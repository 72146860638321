// google fonts
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

:root {
  --primaryColor: #4c96ce;
  --primaryColor-rgb: 76, 150, 206; // rgba color of #4c96ce (primary-color) for opacity
  --DarkBlue: #16346c;
  --naviBlue: #0f2550;
  --textColor: #ffffff;
  --textColor-rgb: 255, 255, 255; // rgba color of #fff (textColor) for opacity
  --activeTextColor: #fff;
  --primaryWebsite: #6c4119;
  --secondaryWebsite: #225c96;
  --secondaryTextWebsite: #2f1b93;
}

$primary: var(--primaryColor);
$secondary: var(--DarkBlue);
$secondaryText: var(--textColor);
$tertiary: var(--naviBlue);
$activeText: var(--activeTextColor);
$primary-rgb: var(--primaryColor-rgb);
$secondaryText-rgb: var(--textColor-rgb);
$success: #6cd098;
$form-error: #f16667;
$black: #1b2430;
$white: #ffffff;
$grey: #767676;
$borderColor: #dbdbdb;
$borderLightBlue: #306899;
$height: 48px;
$radius: 8px;
$warning: #fdb927;

// website related colors
$primaryWebsite: var(--primaryWebsite);
$secondaryWebsite: var(--secondaryWebsite);
$secondaryTextWebsite: var(--secondaryTextWebsite);

* {
  word-wrap: break-word;
  word-break: break-word;
}

div#searchable-dropdown {
  width: 100% !important;
}

.instituteList {
  width: 100%;
  max-height: 212px;
  overflow: auto;
  position: absolute;
  left: 0;
  top: 50px;
  background: $white;
  border: 1px solid $borderColor;
  box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075);
  z-index: 10;
  right: 0;
  border-radius: 6px;

  li {
    list-style-type: none;
    padding: 8px 10px;
    border-radius: 0;
    border: 0;
    margin-bottom: 0;
    font-size: 14px;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px dashed $borderColor;
    }
  }
}

// mixing
@mixin flexBox($alignItems, $justifyContent, $flexwrap) {
  display: flex;
  align-items: $alignItems;
  justify-content: $justifyContent;
  flex-wrap: $flexwrap;
}

@mixin lineClamp($lines) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

hr {
  margin-bottom: 20px !important;
  margin-top: 10px !important;
  border-style: dashed;
  border-color: $borderColor;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

// iframe
iframe {
  display: none !important;
}

div iframe {
  display: block !important;
}

body {
  background-color: #f3f7fe;
  max-width: 100% !important;
}

.text-nowrap {
  white-space: nowrap;
}

// set font family
body,
input,
select,
textarea,
button,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: unset;
  font-weight: unset;
}

h1 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 15px;
}

p {
  font-weight: 400;
}

a {
  color: $primary;
  letter-spacing: 0.3px;
  text-decoration: none;

  &:hover {
    color: $primary;
  }
}

.z-9 {
  z-index: 9;
}
.z-10 {
  z-index: 10;
}
.z-11 {
  z-index: 11;
}
.z-12 {
  z-index: 12;
}
.z-13 {
  z-index: 13;
}
.z-14 {
  z-index: 14;
}

.cursorPointer {
  cursor: pointer;
}

// spinner
.spinner-border {
  width: 1rem;
  height: 1rem;
  border-width: 2px;
}

.refershSpin svg {
  animation: rotateIcon 2s ease-in-out infinite forwards;
}

@keyframes rotateIcon {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// .pageLoder {
//   text-align: center;
//   padding: 20px;
//   width: 100%;
// }

.bg-warning {
  background-color: #fce8ce !important;
  border-color: #fce8ce !important;
}

.bg-success {
  background-color: #dbfff3 !important;
  border-color: #dbfff3 !important;
}

.text-muted {
  color: $grey !important;
}

.text-success {
  color: $success;
}

.text-black {
  color: $black !important;
}

.text-primary {
  color: $primary !important;
}

.text-danger {
  color: $form-error !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

// navbar
.navigationButton {
  position: relative;
  padding-right: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .btn {
    height: 36px;
    line-height: inherit;
    padding: 0 15px !important;
    font-size: 12px;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 24px;
    width: 1px;
    background-color: $borderColor;
    top: 50%;
    transform: translateY(-50%);
  }
  .dropdown {
    .dropdown-toggle {
      background-color: $primary;
      &:hover {
        background-color: $primary !important;
      }
      &:focus,
      &:active {
        background-color: $primary !important;
      }
    }
    .dropdown-menu {
      .dropdown-item {
        &:hover {
          background-color: rgba($primary-rgb, 0.1) !important;
          color: $black !important;
        }
      }
    }
  }
}

// badge
.badge {
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;

  &-primary {
    background-color: $primary !important;
    border-color: $primary !important;
    color: $white;

    &.outline {
      background-color: rgba($primary-rgb, 0.2) !important;
      border-color: $primary !important;
      color: $primary;
    }
  }

  &-warning {
    background-color: #ee8157 !important;
    border-color: #ee8157 !important;

    &.outline {
      border-color: rgba(#ffbf00, 0.2) !important;
      color: #ffbf00 !important;
      background-color: rgba(#ffbf00, 0.2) !important;
    }
  }

  &-danger {
    background-color: $form-error !important;
    border-color: $form-error !important;

    &.outline {
      border-color: #ff8181 !important;
      color: #ff8181 !important;
      background-color: #fff2f2 !important;
    }
  }

  &-success {
    background-color: #6cd098 !important;
    border-color: #6cd098 !important;
    color: $white !important;

    &.outline {
      border-color: #3aa36a !important;
      color: #3aa36a !important;
      background-color: #d9ffea !important;
    }
  }

  &-secondary {
    color: $white;
    background-color: rgba($black, 0.8);
  }
}

.notificationCount {
  font-size: 11px;
  @include flexBox(center, center, wrap);
  // display: flex;
  // align-items: center;
  // justify-content: center;
  background-color: $primary !important;
  border-radius: 50px !important;
  width: 16px !important;
  height: 16px !important;
  position: absolute;
  margin-bottom: 0;
  padding: 0;
  top: 6px;
  left: auto;
  right: 0;
}

.credentialWrap {
  display: flex;
  flex-wrap: wrap;
  width: 100px;

  .badge {
    margin-right: 10px !important;
    margin-bottom: 10px !important;
  }
}

//button
.btn {
  height: 48px;
  border-radius: $radius;
  font-size: 16px;
  margin: 0;
  border-width: 1px !important;

  svg {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }

  &.btn-round {
    border-radius: 50px;
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 500;
    height: auto;
    padding: 4px 15px;
  }

  &.readMore {
    font-size: 12px;
  }

  &.resetBtn {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  &.confirmButton {
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 6px 12px;
    margin-top: 4px;
  }

  &.btn-small {
    padding: 6px 10px;
  }

  &.btn-lg {
    padding: 0 40px !important;
  }

  &.btn-md {
    font-size: 12px;
    height: auto;
    padding: 12px 30px;
  }

  &.success {
    svg,
    path {
      stroke: $success;
    }
  }

  &-primary {
    background-color: $primary !important;
    border-color: $primary !important;

    &:hover {
      background-color: rgba($primary-rgb, 0.8) !important;
      border-color: $primary !important;
    }

    &:active,
    &:active:focus,
    &:focus {
      background-color: $primary !important;
      border-color: $primary !important;
    }

    &.disabled,
    &:disabled {
      background-color: $primary !important;
    }
  }

  &-outline-primary {
    background-color: transparent !important;
    border-width: 1px !important;
    border-color: $primary !important;
    color: $primary !important;
    height: 46px;

    &:hover {
      background-color: $primary !important;
      border-color: $primary !important;
    }

    &:focus,
    &:active,
    &:active:focus {
      background-color: $white !important;
      color: $primary !important;
    }
  }

  &-danger {
    background-color: $form-error !important;
    color: $white;

    &:hover {
      background-color: rgba($form-error, 0.9) !important;
    }
  }

  &-outline-danger {
    background-color: rgba($color: $form-error, $alpha: 0.2) !important;
    border: 1px solid $form-error;
    color: $form-error;

    &:hover {
      background-color: $form-error !important;
    }
  }

  &-warning {
    color: $white !important;
    // background-color: #ee8157 !important;
    background-color: #ffc700 !important;

    &:hover,
    &:focus {
      // background-color: rgba($color: #ee7e3d, $alpha: 0.8) !important;
      background-color: rgba($color: #ffc700, $alpha: 0.8) !important;
    }
  }

  &-success {
    background-color: #6cd098;
    color: $white;

    &:hover,
    &:focus {
      background-color: rgba($color: #6cd098, $alpha: 0.9);
    }
  }

  &-link {
    border: none !important;
    height: auto;
    color: $primary !important;
    padding: 0 6px;
    font-weight: 500;
    border-radius: 0 !important;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      background-color: transparent !important;
      color: $primary !important;
    }

    &:last-child {
      padding-right: 0;
    }

    .pencil {
      svg {
        path {
          stroke: $primary;
        }
      }
    }

    &.remove {
      color: $form-error !important;
      &.resendInvitation {
        svg {
          width: 30px;
          height: 30px;
          fill: $form-error;
        }
      }

      &:hover {
        color: $form-error !important;
      }
    }
  }

  &-outline-link {
    height: auto;
    background-color: rgba($color: $primary-rgb, $alpha: 0.1);
    color: $primary;
    border: 1px solid $primary;
    padding: 4px 10px !important;
    font-size: 12px;
    border-radius: 50px;
    text-transform: capitalize;
    font-weight: 500;
    white-space: nowrap;

    img {
      height: 12px;
      margin-left: 4px;
    }

    &:hover,
    &:focus,
    &:active:focus {
      background-color: #e3faff !important;
      color: $primary !important;
    }

    img {
      margin-left: 10px;
    }
  }

  &.btn-white {
    background-color: $white;
    color: $primary;
    height: auto;
    padding: 8px 20px;
    font-weight: 400;
    border: 1px solid $white !important;
    &:hover,
    &:active,
    &:focus {
      background-color: rgba($white, 0.9) !important;
      color: $primary !important;
    }
    &.outline {
      background-color: transparent;
      color: $white;
    }
  }

  &.h-auto {
    height: auto !important;
    // padding: 8px 10px;
  }
}

.svgStroke {
  svg {
    path {
      stroke: $primary;
    }
  }
}

.agentPayPeriod {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.buttonAction {
  flex: 1;
  @include flexBox(center, flex-end, wrap);

  .dropdown {
    margin-left: 15px;

    > button {
      font-size: 14px;
    }

    .dropdown-menu {
      button {
        &:not(:last-child) {
          border-bottom: 1px dashed $borderColor;
        }

        &:hover {
          background-color: $white;
          color: #4b96cd !important;
        }
      }
    }
  }
}

.dropdownList {
  margin-left: auto;

  .dropdown-toggle {
    background-color: transparent;
    color: $primary;
    border: 1px solid $primary;
    font-size: 14px;

    &:hover {
      background-color: $primary !important;
      color: $primary;
    }

    &:focus {
      background-color: transparent !important;
    }
  }

  &.show {
    .btn.dropdown-toggle {
      background-color: transparent !important;
      color: $primary !important;
      border-color: $primary;

      &:focus {
        background-color: transparent;
      }
    }
  }
}

.customDropdown {
  width: max-content;
  margin-bottom: 6px;

  &.rightSide {
    margin-left: auto !important;
  }

  > button {
    background-color: $white !important;
    padding: 10px;
    height: auto;
    font-size: 12px;
    color: $black;
    text-transform: capitalize;
    color: $grey !important;
    font-weight: 400;
    border: 1px solid $borderColor !important;

    &:hover,
    &:focus,
    &:active:focus {
      background-color: $white !important;
      color: $grey !important;
    }

    img {
      height: 6px;
    }
  }

  .dropdown-menu {
    border-radius: $radius !important;
    width: max-content;

    button {
      padding: 10px;

      &:hover,
      &:focus,
      &:active:focus {
        background-color: rgba($primary-rgb, 0.1);

        color: $black !important;
      }
    }

    &.dropdown-menu-right {
      right: 0;
    }

    // top: 100% !important;
  }
}

.bulkAction {
  margin-left: 10px;

  > .dropdown-toggle {
    text-transform: capitalize;
  }

  &.show {
    > .btn-primary.dropdown-toggle {
      background-color: $primary !important;
    }
  }

  .dropdown-menu {
    .dropdown-item {
      background-color: $white !important;

      &:hover {
        color: $primary !important;
      }
    }
  }
}

#agent_closing_detail_is_document_completed + label {
  font-weight: 500;
  margin-bottom: 0;
}

// form
input {
  &:not(.showarrow) {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      appearance: textfield;
      -moz-appearance: textfield;
    }
  }

  // login autofill background and color change
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px $white inset !important;
    box-shadow: 0 0 0 30px $white inset !important;
    -webkit-text-fill-color: $black;
    font-size: 16px;

    &::placeholder {
      color: $grey;
    }

    ~ label {
      top: 2px;
    }
  }

  // input file hide
  &[type="file"] {
    display: none;
  }
  &.inputRangeCustom {
    padding: 0 !important;
  }
}

.input-group {
  border: 1px solid $borderColor;
  margin-bottom: 0;
  height: $height;
  flex-wrap: nowrap;
  border-radius: $radius !important;

  background: $white;

  .input-group-append,
  .input-group-prepend {
    height: 100%;
    .input-group-text {
      border: none;
      padding: 10px;
      color: $black;
      min-width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none !important;

      i {
        opacity: 1;
        color: $secondary;
      }
    }
  }

  .input-group-prepend {
    background-color: #e3faff;
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;

    ~ .form-control {
      &:disabled {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
  }

  .input-group-append {
    .input-group-text {
      background-color: $white;
      border-top-right-radius: $radius;
      border-bottom-right-radius: $radius;
    }
  }

  input {
    word-break: keep-all;
  }

  input,
  select {
    border: 0 !important;
    font-size: 12px;
    height: auto;
  }

  &:focus-within {
    border-color: $primary;
  }
}

.form-control {
  height: $height;
  border-radius: $radius !important;
  padding-left: 14px;
  color: $black !important;
  font-weight: 500;
  border-color: $borderColor !important;
  font-size: 12px;
  z-index: unset !important;
  padding: 10px !important;
  // white-space: nowrap;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #f1f1f1;
}

select.form-control {
  appearance: none;
  color: $black;
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 25px !important;
}

.custom-select-wrapper {
  position: relative;

  &::after {
    pointer-events: none;
    content: "";
    position: absolute;
    top: 12px;
    right: 2px;
    width: 24px;
    height: 24px;
    background-color: $white;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='grey' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.2rem center;
    background-size: 1.4em;
  }

  &:has(.form-control[disabled]) {
    &::after {
      background-color: #f1f1f1;
    }
  }
}

label {
  color: #1b2430;
  font-size: 14px;
  font-weight: 400;
  z-index: 2;
}

.form-group {
  margin-bottom: 15px;

  label {
    color: $grey;
  }
}

.validation-error {
  font-size: 12px;
  color: $form-error;
  margin-top: 4px;
}

.automationPoint {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  > div {
    margin-right: 20px;
    display: flex;
    align-items: center;

    .circleInfo {
      margin-left: 4px;
    }
  }
}

.chartTools {
  display: flex;
  // align-items: center;
  background-color: $white;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  overflow: hidden;

  .chartInfo {
    position: relative;
    flex: 1 0 200px;
    flex-shrink: 0;
    cursor: pointer;
    flex-grow: 0;
    background-color: #ececec;
    padding: 25px;
    h3 {
      margin-bottom: 0px;
      font-weight: 500;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .custom-control-label {
      font-size: 14px;
      display: flex;
      align-items: center;
    }
    &.selected {
      h3 {
        color: $white;
      }
      .custom-control-label {
        color: $white;
      }
      svg {
        stroke: $white;
      }
    }
    &:nth-child(1) {
      &.selected {
        background-color: #57aaff;
      }
    }
    &:nth-child(2) {
      &.selected {
        background-color: #8350ff;
      }
    }
    &:nth-child(3) {
      &.selected {
        background-color: #2bcac3;
      }
    }
    &:nth-child(4) {
      &.selected {
        background-color: #ff9360;
      }
    }
    .custom-checkbox {
      .custom-control-label::before {
        // border-color: #030303;
        background-color: transparent !important;
      }
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      background-color: transparent !important;
      border-color: $white;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: $white;
    }
  }
}

.help {
  position: absolute;
  right: 10px;
  bottom: 10px;
  svg {
    opacity: 0.5;
    width: 20px;
  }
}

// chips
.ChipsWrap {
  .input-group {
    min-height: 48px;
    height: auto;
    flex-wrap: nowrap;

    > div:first-child {
      border: none !important;
      width: 100%;
      padding-top: 19px !important;
      padding-left: 12px !important;

      &[data-radium="true"] {
        background-color: transparent !important;

        div[data-radium="true"] {
          background-color: #e3faff !important;
          color: $black !important;
          font-size: 13px !important;
          padding: 3px !important;
          margin-bottom: 0 !important;
        }

        div[role="combobox"] {
          input {
            padding: 0 !important;
            margin: 0 !important;
          }
        }
      }
    }

    // >div[data-radium="true"] {
    //     background-color: transparent !important;

    //     [data-radium="true"] {
    //         background-color: #E3FAFF !important;
    //     }
    // }
  }

  > div:not(.input-group) {
    // height: 48px;
    padding-top: 18px !important;
    padding-left: 10px !important;
    border-color: $borderColor !important;

    > div[data-radium="true"] {
      background-color: #e3faff !important;
      color: $black !important;
      font-size: 13px !important;
      padding: 0 0 0 5px !important;

      span[data-radium="true"] {
        height: 24px;
        width: 24px;
        margin-left: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $black;

        &:hover {
          color: $form-error;
          background-color: #ffbdad;
        }
      }
    }

    > div[role="combobox"] {
      input {
        margin-left: 0 !important;
        padding-left: 2px !important;

        &::placeholder {
          color: $grey;
        }
      }
    }
  }
}

// switcher
.custom-switch {
  .custom-control-input {
    &:checked ~ {
      .custom-control-label {
        margin-bottom: 0;

        &::before {
          color: $white;
          border-color: $primary;
          background-color: $primary;
        }
      }
    }

    &:focus ~ {
      .custom-control-label {
        &::before {
          box-shadow: none !important;
        }
      }
    }
  }
}

// checkbox
.custom-checkbox {
  min-height: unset;
  line-height: inherit;

  .custom-control-label {
    color: $black;
    // font-weight: 600;
    cursor: pointer;
    font-size: 12px;

    &::before,
    &::after {
      top: 0px;
      border-radius: 4px;
      width: 18px;
      height: 18px;
    }

    &::before {
      border-color: $primary;
      background-color: $white !important;
    }
  }

  .custom-control-input {
    &:checked ~ {
      .custom-control-label {
        &::after {
          background-color: $primary;
        }
      }
    }

    &:focus ~ {
      .custom-control-label {
        &::before {
          box-shadow: none !important;
        }
      }
    }

    &[disabled],
    &:disabled ~ {
      .custom-control-label {
        cursor: not-allowed;

        &::before {
          border-color: $borderColor;
        }
      }
    }
  }
}

// radio
.custom-radio {
  line-height: 1.6;

  .custom-control-label {
    cursor: pointer;
    margin-bottom: 0 !important;

    &::before {
      background-color: $white;
      border-color: $primary;
      width: 14px;
      height: 14px;
    }

    &::after {
      background-image: none;
      width: 8px;
      height: 8px;
      background: transparent;
      border-radius: $radius;
      top: 7px;
      left: -21px;
    }
  }

  .custom-control-input {
    &:checked ~ {
      .custom-control-label {
        &::before {
          background: $white;
          box-shadow: none !important;
        }

        &::after {
          background: $primary;
        }
      }
    }
  }
}

.css-2613qy-menu {
  height: 100px !important;
  overflow-y: scroll !important;
}

// multi select
.customMultiSelect {
  z-index: 3;

  .css-yk16xz-control,
  .css-1pahdxg-control {
    min-height: 48px;
    border-radius: 10px !important;
    border-color: $borderColor !important;

    .css-1wa3eu0-placeholder,
    .css-1uccc91-singleValue {
      font-size: 14px;
      font-weight: 400;
      color: $grey;
    }
  }

  .css-2613qy-menu {
    z-index: 999;
  }

  .css-b8ldur-Input input {
    height: unset !important;
  }

  .css-xb97g8 {
    cursor: pointer;
  }

  .css-tlfecz-indicatorContainer,
  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding-left: 2px;
    padding-right: 3px;
  }

  input {
    height: unset !important;
  }

  &.zIndex2 {
    z-index: 2;
  }
}
.hideToolbar {
  .ql-toolbar {
    display: none;
  }
}

// date range
.react-daterange-picker {
  height: $height;
  background-color: $white;
  border: 1px solid $borderColor;
  border-radius: $radius;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 99;

  &.react-daterange-picker--disabled {
    cursor: not-allowed !important;
    background-color: #f1f1f1 !important;
  }
  &.react-daterange-picker--disabled * {
    cursor: not-allowed !important;
  }

  .react-daterange-picker__wrapper {
    border: none;

    .react-daterange-picker__inputGroup {
      display: flex;
      align-items: center;
    }

    .react-date-picker__inputGroup__divider {
      color: $grey;
    }

    input,
    .react-daterange-picker__inputGroup__leadingZero {
      border: none !important;
      outline: none !important;
      font-size: 12px !important;
      color: $grey !important;
      background-color: transparent !important;
    }

    @at-root .react-daterange-picker__clear-button {
      svg {
        stroke: $grey;
      }

      &:hover {
        svg {
          stroke: $form-error !important;
        }
      }
    }

    @at-root .react-daterange-picker__button svg {
      width: 14px;
      height: 14px;
    }
  }

  .react-daterange-picker__calendar-button {
    background-image: url("../assets/img/calendar.svg");
    /* Replace with your desired icon path */
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 16px;
    /* Adjust the size of the icon as needed */
    height: 16px;

    svg {
      display: none;
    }
  }
}

// calendar
.react-daterange-picker__calendar {
  .react-calendar__navigation {
    background-color: $white;
    height: auto;

    button {
      min-width: unset;
      width: 36px;
      height: 36px;
      font-size: 18px;
      font-weight: 500;

      &.react-calendar__navigation__label {
        font-size: 14px;
      }
    }
  }
}

.calendarWrap {
  border: 1px solid $borderColor;
  background-color: $white;
  border-radius: $radius;

  .calendarToolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $borderColor;
    width: 100%;

    .calendarTool {
      padding: 10px 6px;

      button {
        padding: 0;
        font-size: 12px;
        color: $grey !important;
        font-weight: 400;
        text-transform: capitalize;

        &:first-child {
          margin-right: 10px;
        }

        &.arrowBtn {
          padding-right: 8px !important;
          margin-left: 0;

          img {
            height: 12px;
          }
        }
      }
    }

    .chooseDate {
      button {
        color: $black !important;
        font-weight: 600;
        font-size: 12px;
        text-transform: capitalize;

        img {
          transform: rotate(90deg);
          margin-left: 5px;
        }
      }
    }

    .calendarFilter {
      // margin-left: auto;
      width: 72px;
      &.custom-select-wrapper {
        &::after {
          top: -4px;
        }
      }

      .form-control {
        height: auto;
        border: none;
        padding: 0 0 0 5px !important;
        color: $grey !important;
      }
    }
  }
}

.rbc-calendar {
  .rbc-time-view {
    .rbc-time-header {
      .rbc-time-header-cell {
        .rbc-header {
          text-align: center;
          padding: 10px;

          a {
            width: 100%;

            span {
              color: $black;
              font-weight: 600;
            }
          }
        }
      }

      .rbc-allday-cell {
        display: none;
      }
    }

    .rbc-time-content {
      border-top: none;

      .rbc-day-slot {
        .rbc-event {
          border-radius: 50px;
          padding: 4px;
          width: auto !important;
          display: inline-block;

          .rbc-event-label {
            margin-bottom: 4px;
          }

          .rbc-event-label,
          .rbc-event-content {
            color: $white;
            font-weight: 500;
            font-size: 10px;
          }
        }
      }
    }
  }
}
.rbc-timeslot-group {
  min-height: 130px;
}

// time picker
.rdtCounters {
  .rdtCounter {
    .rdtCount {
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:last-child {
      .rdtCount {
        border: 1px solid $primary !important;
        color: $primary;
      }
    }
  }
}

// login
.loginWrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  .loginLeft,
  .loginRight {
    width: 100%;
    height: 100vh;
    padding: 20px;
  }

  .loginLeft {
    flex: 1 1;
    background: url("../assets/img/closewise-login-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;

    .projectLogo {
      width: 220px;
      margin: 30px 0 30px;
    }

    .carouselContent {
      .carousel-caption {
        position: unset;
        text-align: left;

        h3 {
          font-size: 22px;
          font-weight: 600;
          margin-bottom: 20px;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          display: -webkit-box;
        }

        p {
          color: rgba($color: $white, $alpha: 0.6);
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          display: -webkit-box;
        }
      }

      .carousel-inner {
        box-shadow: none;
      }

      .carousel-indicators {
        margin: 0;
        justify-content: flex-start;

        li {
          width: 12px;
          height: 4px;
          border-radius: $radius;
          border: none;
          transition: 0.5s ease-in-out;
          background-color: #3c74db;

          &.active {
            width: 24px;
            background-color: $primary;
          }
        }
      }
    }

    .loginDashboard {
      position: absolute;
      right: -1px;
      bottom: -1px;
      margin-left: auto;
      display: block;
      width: 80%;
      height: 58vh;

      .carousel {
        width: 100%;
        height: 100%;
      }

      .carousel-inner {
        box-shadow: none;
        width: 100%;
        height: 100%;

        .carousel-item {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    &.whiteLabel {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $secondary;
      .bigLogo {
        width: auto;
        height: 120px;
      }

      .carouselContent,
      .loginDashboard {
        display: none;
      }
    }
  }

  .loginRight {
    flex: 0 0 50%;
    background-color: $white;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;

    &:has(.signUpForm) {
      align-items: flex-start;
    }

    &.signUpPage {
      overflow: auto;
    }

    h1 {
      font-weight: 600;
    }

    .bigLogo {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
      opacity: 0.8;
    }

    .FormWrap {
      max-width: 500px;
      width: 100%;
      z-index: 2;

      .socialLogin {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px 0;

        button {
          width: 65px;
          height: 65px;
          border: 1px solid $borderColor !important;
          padding: 15px;
          margin-inline: 10px;
          background-color: $white;

          &:hover,
          &:focus,
          &:active:focus {
            background-color: transparent !important;
          }

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }

          img {
            height: 25px;
            width: auto;
          }
        }
      }
    }

    .goBackWrap {
      text-align: center;
      color: $black;
      font-weight: 500;

      a {
        color: $primary;
        margin-left: 5px;
      }
    }

    .orContinue {
      text-align: center;
      position: relative;
      z-index: 1;
      width: 350px;
      margin: 30px auto 0;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        height: 1px;
        background-color: $borderColor;
        z-index: -1;
        width: 100px;
      }

      &::before {
        left: 0;
      }

      &::after {
        left: unset;
        right: 0;
      }

      span {
        color: $grey;
      }
    }
  }
}

// signup
.registrationWrap {
  margin-bottom: 20px;

  h2 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  @at-root .registrationItem {
    display: flex;
    align-items: center;
    // flex-wrap: wrap;

    label {
      cursor: pointer;
      width: 150px;
      height: 120px;
      background-color: #f4f9fc;
      border-radius: $radius;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 0;
      text-align: center;
      margin-right: 15px;
      margin-top: 7px;
      // margin-bottom: 7px;

      span {
        display: block;
        color: $primary;
        margin-top: 10px;
      }

      svg {
        &.whiteIcon {
          display: none;
        }
      }
      &:hover {
        background-color: $primary !important;
        svg {
          &.blueIcon {
            display: none;
          }
          &.whiteIcon {
            display: block;
          }
        }

        span {
          color: $white;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.currentType {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 15px;

  button {
    padding: 0;
    margin-right: 6px;

    img {
      height: 18px;
    }
  }

  span {
    color: $black;
  }
}

// floating label
.floatingLabel {
  position: relative;

  label:not(.custom-control-label) {
    position: absolute;
    top: 14px;
    left: 13px;
    transition: all 0.3s;
    margin-bottom: 0;
    pointer-events: none;
  }

  .form-control {
    font-size: 14px;
    color: $black;
    padding-bottom: 0 !important;
    padding-top: 16px !important;
    padding-left: 12px !important;

    &:focus {
      border-color: $primary !important;

      + label {
        top: 5px;
        font-size: 12px;
        transition: all 0.3s;
      }
    }

    &:not(:placeholder-shown) + label {
      top: 5px;
      font-size: 12px !important;
      transition: all 0.3s;
    }
  }

  textarea {
    &.form-control {
      + label {
        padding-top: 4px;
        background-color: $white;
        display: block;
        width: 94%;
      }

      &:focus ~ {
        label {
          top: 1px;
        }
      }

      &:not(:placeholder-shown) + label {
        top: 1px;
      }
    }
  }

  &.valueAdded {
    label {
      top: 5px;
      font-size: 12px;
      transition: all 0.3s;
    }

    &.reactQuill {
      label {
        border-top-left-radius: $radius;
        border-top-right-radius: $radius;
        top: 1px;
        left: 1px;
        padding-top: 4px;
        padding-left: 12px;
        background-color: $white;
        width: calc(100% - 6px);
        margin: auto;
        display: block;
      }
    }

    .css-g1d714-ValueContainer,
    .css-1hwfws3 {
      margin-top: 17px;
      padding-left: 10px;

      .css-1rhbuit-multiValue {
        background-color: #e3faff !important;

        .css-12jo7m5 {
          font-size: 10px;
          font-weight: 500;
        }
      }
    }
  }

  &.withInputGroup {
    // &:not(.input-group-prepend) {

    .form-control + label {
      left: 52px;
    }

    .form-control:focus + label {
      left: 52px;
    }

    .form-control:not(:placeholder-shown) + label {
      left: 52px;
    }
  }

  &.createSelect {
    .reactselect + label {
      left: 52px;
      top: 5px;
      font-size: 12px;
    }
  }

  &.locationDot {
    .input-group-prepend {
      background-color: transparent;

      .input-group-text {
        &::before {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          border-radius: 50px;
          background-color: $primary;
          box-shadow: 0 0 5px 3px rgba(76, 150, 206, 0.3);
          z-index: 4;
        }

        &.stopLocation {
          &::before {
            background-color: #6cd098 !important;
            box-shadow: 0 0 5px 3px rgba(108, 208, 152, 0.4);
          }

          &::after {
            position: absolute;
            bottom: 18px;
            content: "";
            width: 1px;
            height: 70px;
            z-index: 2;
            border-left: 2px dashed $primary;
          }
        }

        &.endLocation {
          &::before {
            background-color: $form-error !important;
            box-shadow: 0 0 5px 3px rgba(241, 102, 103, 0.4);
          }

          &::after {
            position: absolute;
            bottom: 18px;
            content: "";
            width: 1px;
            height: 70px;
            z-index: 2;
            border-left: 2px dashed $primary;
          }
        }
      }

      ~ .form-control {
        padding-left: 0 !important;
        border-radius: 8px !important;

        + label {
          left: 40px;
        }
      }
    }

    .validation-error {
      margin-left: 34px;
    }
  }

  &.withColorInput {
    .input-group {
      .input-group-text {
        padding: 5px !important;

        input {
          height: 30px !important;
          background-color: transparent;
          padding: 0 !important;
        }
      }
    }
  }

  &.withColorInput {
    .input-group {
      .input-group-text {
        padding: 5px !important;

        input {
          height: 30px !important;
          background-color: transparent;
          padding: 0 !important;
        }
      }
    }
  }

  &.withChips {
    .input-group > div:first-child {
      flex: auto;
      border: none !important;
      padding: 0 !important;

      > div[role="combobox"] {
        height: 100%;

        input {
          height: 100%;
          margin: 0 !important;
          padding: 16px 0 0 12px !important;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
}

// sidebar
.sidebar {
  width: 220px;

  &::after {
    background: $secondary;
  }

  .sidebar-wrapper {
    width: 100%;
  }

  // logo
  .logo {
    padding: 9px 10px;

    &::after {
      width: 100%;
      right: 0;
      background-color: rgba($secondaryText-rgb, 0.2);
    }

    a {
      display: block;
      text-align: center;

      img {
        height: 45px;
      }

      i {
        color: $white;
      }
    }
  }

  .sidebar-wrapper {
    max-height: calc(100vh - 140px);
    height: auto;
    overflow-x: hidden;
    scrollbar-color: $secondary;
    scrollbar-width: thin;
    padding-bottom: 0px;

    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  //menu
  .mainNav {
    li {
      a {
        color: rgba($secondaryText-rgb, 0.8);
        margin: 6px;
        padding: 14px 12px;
        border-radius: $radius;
        position: relative;
        // z-index: 1;
        overflow: hidden;
        display: flex;
        align-items: center;
        line-height: 1.4;

        &::after {
          display: none !important;
        }

        // img {
        //   height: 18px;
        // }

        svg {
          width: 20px;
          height: 20px;
          fill: $secondaryText !important;
          &.fillNone,
          .fillNone {
            fill: none !important;
          }
          &.lucide-settings {
            fill: none !important;
            stroke: $secondaryText;
            stroke-width: 1px;
          }
        }
        .arrowIcon {
          svg {
            fill: transparent !important;
            width: 18px;
            height: 18px;
          }
        }

        span {
          text-transform: capitalize;
          margin-left: 10px;
        }

        // i {
        //   float: none;
        //   font-size: 12px;
        //   margin-right: 0;
        // }

        .arrowIcon {
          position: absolute;
          right: 10px;
          height: 8px;
          transition: 0.3s ease-in-out;
        }

        &[aria-expanded="true"] {
          .arrowIcon {
            transform: rotate(180deg);
          }
          // ~ .innerMenu {
          //   .nav {
          //     li {
          //       &.active {
          //         a {
          //           // background-color: $tertiary;
          //           span {
          //             color: rgba($secondaryText-rgb, 1) !important;
          //           }
          //           &::before {
          //             background-color: $secondaryText;
          //           }
          //         }
          //       }
          //     }
          //   }
          // }
        }
      }

      // nav
      .nav {
        margin-left: 30px;

        li {
          a {
            padding: 6px;
            position: relative;

            &::before {
              width: 6px;
              height: 6px;
              background-color: rgba($secondaryText-rgb, 0.9);
              border-radius: $radius;
              position: absolute;
              top: 50% !important;
              transform: translateY(-50%);
              left: 0;
              content: "";
              border: none !important;
            }
            &:hover {
              color: rgba($secondaryText-rgb, 1);

              &::before {
                background-color: rgba($secondaryText-rgb, 1);
              }
            }
          }

          &.active {
            a {
              span {
                color: rgba($secondaryText-rgb, 1) !important;
              }
              &::before {
                background-color: rgba($secondaryText-rgb, 1);
              }
            }
          }
        }
      }

      &.active {
        > a {
          background: $tertiary;
          span {
            color: $activeText !important;
          }
          > svg {
            fill: $activeText !important;
            &.lucide-settings {
              fill: none !important;
              stroke: $secondaryText;
            }
            .arrowIcon {
              svg {
                fill: transparent !important;
                stroke: $white;
              }
            }
          }

          &::before {
            display: block !important;
          }
        }
      }
    }

    > li {
      > a {
        &:hover {
          background: $tertiary;
          color: $activeText !important;
          > svg {
            fill: $activeText !important;
            &.lucide-settings {
              fill: none !important;
              stroke: $secondaryText;
            }
          }
          .arrowIcon {
            svg {
              fill: transparent !important;
            }
          }
        }

        &::before {
          content: "" !important;
          position: absolute;
          top: 0 !important;
          right: inherit !important;
          left: 0;
          width: 4px;
          height: 100%;
          background-color: $primary;
          z-index: 9;
          border: none !important;
          display: none;
          opacity: 0;
        }
      }
    }
  }

  // sidebar schedule box
  .sidebarScheduleBox {
    z-index: 4;
    // background-color: $tertiary;
    // color: $white;
    position: relative;
    margin: 15px 15px 0;
    // padding: 20px;
    // border-radius: $radius;
    text-align: center;
    button {
      height: auto;
      font-size: 13px;
      padding: 8px 15px;
      font-weight: 500;
    }
  }
}

// main panel
.main-panel {
  background-color: unset;

  .content {
    padding-inline: 15px;
    // margin-top: 15px;
    padding-top: 15px;
  }

  .navbar {
    background-color: #f3f7fe;
    padding-inline: 15px;
    position: sticky;
    border-bottom: 1px solid $borderColor;
    top: 0;
    z-index: 999;

    .container-fluid {
      padding: 0;
    }

    .navbar-brand {
      display: none;
      height: 50px;

      img {
        height: 100%;
      }
    }

    .navbar-toggle {
      button {
        padding-left: 0;
      }
    }

    // Header Page Info
    .headerPageInfo {
      display: flex;
      align-items: center;

      button {
        padding-right: 10px !important;
        line-height: inherit;
        border-right: 1px solid rgba($black, 0.3) !important;
        border-radius: 0;
        margin-right: 10px;

        img {
          height: 15px;
        }
      }

      .pageTitle {
        font-size: 16px;
        color: $black;
        font-weight: 600;
        margin-bottom: 0;
        line-height: inherit;

        span {
          display: block;
          font-weight: 400;
          font-size: 12px;
          line-height: 1;
        }
      }
    }

    .navbar-nav {
      .nav-link {
        margin-right: 0;
      }
    }
  }
}

.mainHeading {
  font-weight: 600;
  letter-spacing: 0.3px;
  font-size: 16px;
  margin-bottom: 10px;
}

// filter
.filterContainer {
  display: flex;
  margin-bottom: 20px;

  .filterIcon {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $black;
    font-weight: 500;
    white-space: nowrap;
    margin-right: 25px;

    img {
      margin-right: 6px;
      height: 16px;
    }
    svg {
      // transform: rotate(-90deg);
      width: 18px;
      height: 18px;
      margin-right: 5px;
      line {
        stroke: $primary;
      }
    }
  }

  @at-root .filterWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    .form-group {
      margin-bottom: 0;
      margin-right: 15px;
      margin-top: 7px;
      margin-bottom: 7px;
      max-width: 200px;

      &.noshadow {
        box-shadow: none;
      }

      &.dateRange {
        max-width: inherit;
      }

      &.searchTable {
        max-width: 300px;
        width: 100%;

        .input-group-text {
          background-color: $white;
          border: none;
        }

        input {
          padding-left: 0 !important;
        }
      }
    }

    .customMultiSelect {
      z-index: 3;

      .css-yk16xz-control,
      .css-1pahdxg-control {
        min-height: 42px !important;
      }
    }

    .withChips {
      max-width: 220px;
      width: 100%;

      .customMultiSelect {
        .css-yk16xz-control {
          border: none !important;
          box-shadow: 0 0 10px 0 #69b5ed29;
        }
      }
    }

    .form-control,
    .input-group,
    .react-daterange-picker {
      height: 40px !important;
      border: none;
      box-shadow: 0 0 10px 0 #69b5ed29;
    }

    .input-group {
      .form-control {
        box-shadow: none;
      }
    }

    .form-control {
      font-weight: 400;
      color: #808080 !important;
    }

    select.form-control {
      min-width: 80px;
    }

    label {
      display: block;
      font-size: 12px;
    }
  }
}

.MuiFormGroup-root {
  .input-group {
    max-width: 240px;
  }
}

// help
.helpSearch {
  max-width: 500px;
  margin: 30px auto;

  .input-group {
    button {
      height: auto;
    }
  }
}

.commentLog {
  width: 200px !important;
  overflow: hidden;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}

.reactselect {
  flex: 1;
  // overflow: hidden;

  .css-yk16xz-control,
  .css-1pahdxg-control {
    height: 100%;
    border: none !important;
    box-shadow: none;

    .css-g1d714-ValueContainer,
    .css-1hwfws3 {
      top: 10px;
    }
  }
}

// pagination
.paginationWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  .pagination {
    margin-bottom: 0;

    .page-item {
      margin-inline: 0 6px;

      .page-link {
        border: 1px solid $borderColor;
        background-color: $white;
        padding: 0;
        box-shadow: none;
        color: $black !important;

        i {
          font-size: 12px;
          font-weight: 400;
        }
      }

      &.active {
        .page-link {
          background-color: $primary;
          border-color: $primary;
          color: $white !important;
        }
      }
    }
  }
}

.cardPagination {
  .paginationWrap {
    margin-top: 0 !important;
  }
}

// .signerPopover {
//   .popover {
//     max-width: inherit;
//     box-shadow: 0 2px 4px 1px rgba($black, 0.02);
//   }

//   .popover-inner {
//     .popover-header {
//       font-weight: 600;
//     }
//   }
// }

// table

.CustomTableWrapper {
  border: 1px solid $borderColor;
  border-radius: 10px;

  &.withAccordian {
    background-color: $white;

    .card {
      box-shadow: none;
      border: none;
    }

    .accordianButton {
      text-transform: capitalize !important;
      padding-bottom: 15px !important;
      padding-top: 15px !important;
      font-weight: 500;
      font-size: 12px;

      img {
        margin-left: 4px;
        transition: 0.5s ease-in-out;
        transform: rotate(0deg);
      }

      &.show {
        img {
          transform: rotate(180deg);
        }
      }
    }

    .accordianCollapse {
      margin: 20px 20px 10px;
    }
  }

  &.credentialsList {
    tr {
      td {
        font-weight: 400;

        .addButton {
          margin: 10px 0;
          padding: 10px 30px;
        }
      }
    }
  }

  &.bulkUploadTable {
    .table-responsive {
      overflow: auto !important;
      max-height: 700px;
    }
    select.form-control {
      height: 36px;
    }
  }
}

.clientDetails {
  background-color: #e8f5ff;
  padding: 20px;
  border-radius: $radius;
  display: flex;
  flex-wrap: wrap;

  li {
    margin: 7px 15px 7px 0;
    flex: auto;
    flex-basis: 180px;
    flex-grow: 0;
    font-size: 12px !important;

    h5 {
      font-size: 12px;
      font-weight: 600;
      margin-top: 4px;
      margin-bottom: 0;
    }
  }
}

.questionPoint {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  li {
    flex: 1;
    flex-basis: calc(200px - 15px);
    position: relative;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-right: 15px;
    padding: 15px;
    font-size: 12px;
    line-height: 1.6;
    box-sizing: border-box;
    padding-bottom: 80px;

    .answer {
      text-align: center;
      margin-top: 10px;
      font-size: 16px;
      font-weight: 500;
      position: absolute;
      bottom: 15px;
      left: 15px;
      // transform: translateX(-50%);
      img {
        margin-right: 6px;
        height: 20px;
      }
    }
    &.yes {
      background-color: rgba($success, 0.2);
    }
    &.No {
      background-color: rgba($form-error, 0.2);
    }
    // &:last-child{
    //   margin-right: 0;
    // }
  }
}

.clientDetails {
  background-color: #e8f5ff;
  padding: 20px;
  border-radius: $radius;
  display: flex;
  flex-wrap: wrap;

  li {
    margin: 7px 15px 7px 0;
    flex: auto;
    flex-basis: 180px;
    flex-grow: 0;
    font-size: 12px !important;

    h5 {
      font-size: 12px;
      font-weight: 600;
      margin-top: 4px;
      margin-bottom: 0;
    }
  }
}

// userinfo
.userInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .userImage {
    width: 30px;
    height: 30px;
    background-color: $white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    margin-right: 10px;
    justify-content: center;

    img {
      padding: 6px;
      width: 29px;
      height: 29px;
      object-fit: contain;
    }
  }
}

.upgradeText {
  font-size: 14px;
  color: $form-error;
  margin: 10px;
}

.invoicePdf {
  display: flex;
  align-items: center;
  max-width: 250px;

  > i {
    color: $form-error;
  }

  .invoiceName {
    margin-left: 10px;
    font-weight: 600;
    margin-right: 5px;
  }

  .badge {
    margin-bottom: 0;
    font-size: 10px !important;
    padding: 3px 10px !important;

    i {
      margin-right: 4px !important;
      font-weight: 400 !important;
    }
  }
}

// table

.table-responsive {
  padding-bottom: 0 !important;
  overflow: unset;
  overflow-x: auto;
}

.table {
  border-radius: 10px;
  width: 100%;
  margin: 0;
  background-color: $white;
  font-size: 12px !important;

  tr {
    th {
      background-color: #f7f7f7;
      border-top: 0 !important;
      text-transform: capitalize !important;
      border-bottom: 1px solid $borderColor !important;

      &::first-letter {
        text-transform: capitalize !important;
      }
    }

    th,
    td {
      padding: 12px 20px !important;
      color: $black;
    }
  }

  .checkBoxColumn {
    width: 40px !important;
    padding-right: 0px !important;
  }

  .custom-control {
    padding-left: 0;

    .custom-control-label {
      &::before,
      &::after {
        left: 0;
      }
    }
  }

  thead {
    tr {
      th {
        font-size: 12px !important;
        font-weight: 500 !important;
        white-space: nowrap;

        &:first-child {
          border-top-left-radius: 10px;
        }

        &:last-child {
          border-top-right-radius: 10px;
        }
      }
    }
  }

  &.table-striped {
    tbody {
      tr:nth-of-type(odd) {
        background-color: $white !important;
      }

      tr:nth-of-type(even) {
        background-color: #f8f9fd !important;
      }

      tr {
        &.rowStatus {
          background-color: #efefef !important;
        }
      }

      tr {
        &.isWhiteLabel {
          background-color: #d7eafd !important;
        }
      }

      td {
        border-top: none;
      }

      tr {
        &.isRowSelected {
          background-color: #e2f0fb !important;
        }

        &.agentBlocked {
          background-color: #ffd9e1 !important;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        font-weight: 400;

        // form
        .form-control {
          height: auto;
          border-radius: 50px;
        }

        .tableLink {
          color: $primary !important;
          font-weight: 500;
          cursor: pointer;
        }

        button {
          height: auto;
          text-transform: capitalize;
          font-size: 12px;
        }

        .action {
          .actionBtn {
            img {
              height: 18px;
            }
          }
        }
      }
    }
  }
}

.tableRating {
  .star-ratings {
    .star-container {
      padding-right: 0px !important;
      padding-left: 2px !important;

      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}

.table-overlay {
  position: absolute;
  background: rgba(34, 34, 34, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;

  div {
    color: $white;
    font-size: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
  }
}

.tableTotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $borderColor;
  background-color: $white;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;

  p {
    color: $black;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0;
  }
}

.docStatus {
  width: 110px;

  .actionBtn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 4px !important;
    padding: 0 !important;

    span {
      &.value {
        margin-left: 5px;
        white-space: normal;
        text-align: left;
        flex: auto;
        color: $black;
        font-weight: 600;
      }

      &.fa-circle {
        font-size: 10px;
      }
    }
  }
}

// user info
.tableUserInfo {
  @include flexBox(center, flex-start, wrap);
  // display: flex;
  // align-items: center;
  // justify-content: flex-start;
  width: 150px;

  .userImage {
    width: 25px;
    height: 25px;
    border-radius: 50px;
    overflow: hidden;
    margin-right: 10px;
    border: 1px solid rgba($borderColor, 0.3);
    background-color: #f7f7f7;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .userContent {
    color: $black;
    flex: 1;

    span {
      display: block;

      &.signerName {
        font-weight: 600;
        color: $primary;
        cursor: pointer;
      }

      &.dateTimeFormat {
        font-weight: 600;

        span {
          font-weight: 400;
          display: inline-block;
        }
      }
    }
  }
}

.nodata {
  width: 100%;
  padding: 20px;
  text-align: center;

  img {
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 0;
  }
}

select.status {
  border-radius: 50px !important;
  padding: 2px 6px !important;
  border: 1px solid $borderColor !important;
  position: relative;
  background: none;

  &.danger {
    border-color: #ff8181 !important;
    color: #ff8181 !important;
    background-color: #fff2f2 !important;
  }

  &.warning {
    border-color: #ee7e3d !important;
    color: #ee7e3d !important;
    background-color: #fef1e9 !important;
  }

  &.success {
    border-color: #3aa36a !important;
    color: #3aa36a !important;
    background-color: #d9ffea !important;
  }

  option {
    background-color: $white !important;
    color: $black !important;

    &[disabled] {
      color: rgba($black, 0.6) !important;
    }
  }
}

.customSelectWrap {
  position: relative;

  select {
    &.danger + .downArrow {
      color: #ff8181 !important;
      background-color: #fff2f2 !important;
    }

    &.warning + .downArrow {
      color: #ee7e3d !important;
      background-color: #fef1e9 !important;
    }

    &.success + .downArrow {
      color: #3aa36a !important;
      background-color: #d9ffea !important;
    }
  }

  .downArrow {
    position: absolute;
    right: 3px;
    top: 1px;
    font-size: 11px;
    height: 21px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 21px;
    background-color: #fff2f2 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }
}

// mileage log reporting agent
.locationWrap {
  span {
    display: block;
    width: 200px;
    line-height: 1.6;
  }

  button {
    margin-top: 6px !important;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    color: $black;

    img {
      margin-right: 4px;
      height: 12px;
    }
  }
}

// card
.CardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  h2 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    margin-right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.card {
  box-shadow: 0 0 10px 0 #69b5ed29;
  // border: 1px solid $borderColor;
  border-radius: $radius;

  .card-header {
    background-color: #f7f7f7 !important;
    padding: 10px 15px;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    border-bottom: 1px solid $borderColor;

    .card-title {
      margin-top: 0;
      margin-bottom: 0px;
      font-weight: 600;
    }

    p {
      margin-top: 0;
      margin-bottom: 0px;
      font-size: 12px;
      color: #767676;
    }
  }

  label {
    font-size: unset;
    color: unset;
  }

  .card-body {
    padding: 15px;
  }
}

// order detail
// chat container
.documentList {
  list-style: none;

  li {
    display: flex;
    align-items: center;
    border: 1px solid $borderColor;
    border-radius: $radius;
    padding: 15px;
    &.documentNoVisible {
      justify-content: center;
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .documentItem {
      flex: auto;
      max-width: 350px;
      font-size: 12px;
      margin-right: 10px;

      .userInfo {
        width: 200px;
        .userImage {
          width: 25px;
          height: 25px;
          background-color: $white;

          &.docFile {
            background-color: $primary;
          }

          &.pdfFile {
            background-color: $form-error;
          }

          img {
            height: 25px;
            width: auto;
          }
        }

        .userContent {
          flex: 1;
        }
      }

      h6 {
        font-weight: 600;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        margin-bottom: 0;

        span {
          font-weight: 400;
          color: $grey;
          margin-left: 1px;
          text-transform: lowercase;
        }
      }
    }

    .actionItem {
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        &:nth-last-child(1) {
          border-left: 1px solid $borderColor !important;
          padding-left: 20px;
          margin-left: 10px;
        }

        img {
          height: 14px;
        }
      }
    }
  }

  &.signingDocument {
    li {
      .documentItem {
        max-width: inherit !important;
        // flex: 1;
      }
    }
  }
}

.chatWrapper {
  margin-top: 20px;
  &.commentNote {
    .chatWrap {
      .chatItem {
        background-color: $white;

        .Chatcontent {
          p {
            font-size: 14px;
            color: rgba($black, 0.9);
          }
        }
      }
    }
  }
}

.chatWrap {
  margin-bottom: 15px;
  .bg-grey {
    background-color: #f5f5f5;
  }

  .date {
    color: $black;
    font-size: 12px;
  }
}

.chatItem {
  border: 1px solid $borderColor;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: $radius;

  .chatHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;

    h2 {
      margin: 0;
      font-size: 12px;
      font-weight: 500;
      flex: auto;
    }

    .action {
      text-align: right;
      span {
        white-space: nowrap;
      }
    }
  }

  .Chatcontent {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: rgba($color: $black, $alpha: 0.8);
    p {
      margin-bottom: 5px;
    }
  }
}

.notarialogWrap {
  font-size: 14px;

  i {
    color: $primary;
  }

  h6 {
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 5px;
  }
}

.permissionWrapper {
  padding: 10px;
  border-radius: $radius;

  &.open {
    background-color: $white;
  }

  .permissionHead {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      color: $black;
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 500;
    }

    i {
      color: $black;
      font-size: 10px;
      margin-right: 5px;
    }
  }

  .permissionBody {
    padding-left: 20px;
    padding-top: 15px;

    .custom-control {
      &:not(:last-child) {
        margin-bottom: 10px;
      }

      label {
        font-weight: 400;
      }

      .custom-control-label {
        &::before,
        &::after {
          background-color: #efefef;
          border: none;
          width: 16px;
          height: 16px;
        }
      }

      .custom-control-input {
        &:checked ~ {
          .custom-control-label {
            &::after {
              background-color: $primary;
            }
          }
        }
      }
    }
  }
}

// swal2 popup

.swal2-popup {
  max-width: 440px;
  padding: 20px;

  width: 100%;
}

.swal2-container.swal2-backdrop-show {
  background-color: rgba($black, 0.5);
}

.swal2-popup {
  background-color: #f4f7fe !important;

  .swal2-icon.swal2-warning {
    .swal2-icon-content {
      font-size: 60px;
    }
  }

  .swal2-styled {
    border-radius: $radius;
  }

  .swal2-title {
    // padding: 1em 0 0;
    margin-bottom: 15px;
    font-size: 18px;
    color: $black;
  }

  .swal2-html-container {
    font-size: 14px;
    color: $black;
    font-weight: 600;
    margin-inline: 0 !important;
    line-height: 1.7;
    margin-top: 0;
  }

  .swal2-actions {
    margin-top: 10px !important;
    justify-content: center;

    .swal2-confirm {
      background-color: $primary !important;
      box-shadow: none !important;
      border-color: $primary !important;
    }

    .swal2-cancel {
      background-color: $form-error !important;
    }
  }

  &.alert-swal-modal {
    padding: 40px 30px;

    .swal2-image {
      margin-top: 0;
      margin-bottom: 0;
    }

    .swal2-title {
      color: $primary;
    }

    .swal2-confirm {
      width: 200px;
    }
  }
}

// quill
.quill {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  background-color: $white;
  border: 1px solid $borderColor;
  border-radius: $radius;
  position: relative;
  z-index: 1;

  .ql-container {
    .ql-editor {
      min-height: 100px;
      max-height: 250px;
      padding-bottom: 0px;
      padding-top: 22px;
      padding-inline: 12px;
      font-weight: 500;

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-thumb {
        // width: 5px;
        background-color: $borderColor;
      }
    }
  }

  .ql-toolbar,
  .ql-container {
    border: none !important;
    border-radius: 0;
    z-index: 2;
  }

  .ql-toolbar {
    border-top: 1px solid $borderColor !important;
  }
}

.tooltip-inner {
  font-size: 12px;
}

// nav tabs
.greyBg {
  background-color: #f7f7f7;
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}

.nav {
  &.nav-pills,
  &.nav-tabs {
    justify-content: flex-start;
    border-bottom: 1px solid $borderColor;

    .nav-item {
      .nav-link {
        padding: 8px 10px;
        cursor: pointer;
        border: none;
        color: $black;
        font-size: 12px;
        position: relative;
        font-weight: 600;
        margin-right: 10px !important;

        &:hover {
          color: $primary;
          svg,
          path {
            fill: $primary !important;
          }
        }

        &.active {
          color: $primary !important;
          background-color: transparent;

          &::after {
            background-color: $primary;
          }
        }
      }
    }
  }
  &.StandardFeeTabs {
    .nav-item {
      .nav-link {
        @include flexBox(center, flex-start, nowrap);
        button {
          padding-left: 0;
          svg {
            margin-left: 2px;
            width: 13px;
            height: 13px;
            stroke: $form-error;
            fill: none !important;
          }
        }

        &:hover {
          button {
            svg,
            path {
              fill: none !important;
            }
          }
        }
      }
    }
  }

  &.nav-tabs {
    .nav-item {
      .nav-link {
        &::before {
          top: auto;
          bottom: -1px !important;
          left: 0;
          width: 100%;
          height: 3px;
          background-color: $primary;
          border: none !important;
        }

        &::after {
          display: none !important;
        }

        img {
          height: 16px;
          margin-right: 6px;
        }
      }
    }
  }
}

.tab-content {
  .tab-pane {
    padding: 15px;
  }
}

.productTypeRepeat {
  & + .productTypeRepeat {
    border-top: 1px dashed $borderColor;
    margin-top: 15px;
    padding-top: 15px;
  }
}

.verticalTabs {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  > .nav-tabs {
    width: 220px;
    background-color: $white;
    border: 1px solid rgba($primary-rgb, 0.2);
    border-radius: $radius;

    .nav-item {
      width: 100%;
      overflow: hidden;

      .nav-link {
        padding: 12px 15px;
        margin: 2px 0 !important;
        display: flex;
        align-items: center;

        svg,
        path {
          width: 16px;
          height: 16px;
        }
        svg {
          margin-right: 10px;
        }

        &::before {
          width: 3px;
          height: 100%;
        }
        svg {
          width: 16px;
          height: 16px;
          margin-right: 6px;
          svg,
          path {
            fill: rgba($black, 0.7);
          }
        }

        &.active {
          background-color: rgba($primary-rgb, 0.1);
          svg {
            path {
              stroke: $primary;
              fill: rgba($black, 0.7);
            }
          }
        }
      }

      &:first-child {
        .nav-link {
          margin-top: 0 !important;
        }
      }

      &:last-child {
        .nav-link {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  .tab-content {
    width: calc(100% - 220px);

    .tab-content {
      width: 100% !important;
    }

    .tab-pane {
      padding-top: 0;
    }
  }
}

// upload button

.profilePic {
  width: 100px !important;
  height: 100px !important;
  margin: 20px auto;
  border-radius: 50px;
  border: 1px solid $borderColor;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.uploadDocument {
  cursor: pointer;
  background-color: $white;
  border: 1px dashed $primary;
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $radius;

  img {
    height: 40px;
    margin-right: 10px;
  }

  .uploadLabel {
    text-align: left;

    h4 {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      color: $black;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 250px;
      width: 100%;
    }

    p {
      margin: 0;
      color: $black;
      font-weight: 400;
      font-size: 12px;
    }
  }
}

// upload preview image
.uploadPreview {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  background-color: $white;
  padding: 10px;
  border-radius: $radius;
  border: 1px solid $borderColor;

  .uploadedImg {
    height: 30px;
    width: 30px;
    background-color: $white;

    img {
      height: 100%;
      object-fit: contain;
    }
  }

  span {
    font-size: 12px;
    font-weight: 600;
    flex: 1;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    margin-inline: 10px;
  }
}

.deletePreview {
  border-radius: 50px;
  width: 25px !important;
  height: 25px !important;
  padding: 0;

  img {
    height: 12px;
  }
}

.circularProgress {
  position: absolute;
  z-index: 9;

  .currentPercentage {
    position: absolute;
    bottom: -6px;
    left: 44px;
    font-size: 10px;
    padding: 2px 10px;
    background-color: #2fe47f;
    color: $white;
    border-radius: 50px;
  }
}

.signaturePreview {
  width: 80px;
  height: 80px;
  position: relative;
  border: 1px solid $borderColor;
  border-radius: $radius;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;

  .deletePreview {
    position: absolute;
    top: -6px;
    right: -6px;
    width: 18px !important;
    height: 18px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 8px;
    }
  }
}

.profileCompanyLogo {
  width: auto;
  margin-left: auto !important;
  margin-right: auto !important;
  position: relative;
  display: inline-block;
  img {
    width: auto;
    cursor: pointer;
    height: 100%;
    border: 1px solid $borderColor;
    background-color: #f5f5f5;
  }
  &.rectangle {
    height: 100px;
  }
  &.square {
    height: 140px;
  }
  i {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background-color: $primary;
    display: flex;
    color: $white;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -10px;
    right: -10px;
  }
}

.uploadProfile {
  background: $white !important;
  border: 1px solid $borderColor;
  box-shadow: 0 0 2px 8px rgba($primary-rgb, 0.1);
  cursor: pointer;
  width: 120px;
  height: 120px;
  border-radius: $radius;
  display: block;
  margin: 25px auto 45px !important;
  position: relative;
  border-radius: 100px;

  label {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-bottom: 0;
  }

  img {
    height: 100%;
    // aspect-ratio: 1/1;
    width: 100%;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    object-position: center;
    -o-object-position: center;
  }

  .icon {
    position: absolute;
    bottom: -5px;
    right: 0;
    width: 34px;
    height: 34px;
    background-color: $primary;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      width: auto;
      height: auto;
      object-fit: unset;
    }
  }

  i {
    font-size: 16px;
    color: $white;
    width: 34px;
    height: 34px;
    line-height: 34px;
    position: absolute;
    bottom: -5px;
    right: 0;
    background-color: $primary;
    border: 0;
    text-align: center;
    border-radius: 50px;
  }

  .replacePhoto {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba($color: $black, $alpha: 0.8);
    color: $white;
    font-size: 12px;
    border-radius: 100px;
    font-weight: 500;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease-in-out;

    img {
      height: 20px;
      width: auto;
      margin-bottom: 5px;
      border-radius: 0;
    }
  }

  &:hover {
    .replacePhoto {
      opacity: 1;
      visibility: visible;
    }
  }
}

.personalwebLogo {
  text-align: center;
  margin-bottom: 30px;

  .uploadProfile {
    margin-bottom: 20px !important;
  }
}

.uploadUserInfo {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba($color: $black, $alpha: 0.8);
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  h3 {
    font-size: 16px;
    color: $white;
    margin-bottom: 10px;
    font-weight: 500;
  }

  span {
    background-color: $primary;
    padding: 6px 20px;
    color: $white;
    font-weight: 400;
    border-radius: 50px;

    img {
      width: 14px;
      margin-right: 2px;
    }
  }
}

.uploadBtnTable {
  color: $primary;
  cursor: pointer;
  background-color: #e3faff;
  border: 1px solid $primary;
  border-radius: $radius;
  padding: 2px 30px;

  i {
    margin-left: 0 !important;
  }
}

.uploadBtn {
  text-decoration: none !important;
  cursor: pointer;
  background-color: $primary;
  color: $white !important;
  padding: 8px 20px;
  border-radius: 6px;
  margin-bottom: 0 !important;
  font-weight: 500;
  position: relative;
  font-size: 12px;

  .csv-label {
    margin-bottom: 0 !important;
    color: $white;
    cursor: pointer;
  }

  svg {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }

  input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    opacity: 0;
    display: block;
    height: 40px;
  }

  input {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    opacity: 0;
    display: block;
  }

  img {
    height: 14px;
    margin-right: 4px;
    cursor: pointer;
  }

  &.outline {
    background-color: transparent;
    color: $primary !important;
    border: 1px solid $primary;
    padding: 8px 20px;
    font-size: 12px;
    text-transform: uppercase;

    img {
      height: 12px;
    }
  }
}

.uploadLink {
  color: $primary !important;
  font-weight: 600;
  cursor: pointer;
  font-size: 12px;
  margin-bottom: 0 !important;
  background-color: transparent;
  // padding-inline: 10px !important;
  svg {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
}

.footer {
  padding: 15px;
  width: 100%;
  letter-spacing: 0.3px;
  color: $grey;

  .copyright {
    font-size: 14px;
    padding-right: 8px;
    float: none;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  a {
    padding: 2px 10px 0;
    color: $grey !important;
    text-decoration: none;
  }

  .credits {
    padding-right: 0;

    a {
      color: #31c5c7 !important;
      text-decoration: none !important;
      letter-spacing: 0.3px;
      padding: 0;
    }
  }

  .footer-nav {
    padding-left: 0;
  }
}

.uploadDocumentInline {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: $white;
  border: 1px solid $borderColor;
  border-radius: $radius;
  // margin-bottom: 30px;

  .uploadLink {
    border-right: 1px solid $borderColor;
    padding-right: 30px;
    margin-right: 10px;
  }

  .uploadList {
    flex: 1;
    display: flex;
    align-items: center;

    .uploadItem {
      background-color: #e3faff;
      padding: 4px;
      display: flex;
      align-items: center;
      border-radius: $radius;
      margin-right: 15px;

      img {
        margin-right: 5px;
      }

      span {
        font-size: 13px;
        font-weight: 500;
        color: #4c96ce;
        max-width: 200px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    button {
      height: auto !important;
      padding-right: 0;

      img {
        margin-right: 0;
        padding-right: 0;
      }
    }
  }
}

.attachementWrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;

  .uploadItem {
    width: 60px;
    height: 60px;
    border-radius: $radius;
    border: 1px solid $borderColor;
    margin-right: 5px;
    background-color: $white;
    position: relative;
    cursor: pointer;

    .removeIcon {
      position: absolute;
      top: -5px;
      right: -5px;
      width: 16px;
      height: 16px;
      background-color: $form-error;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 10px;
        width: auto;
      }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    label {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        height: 24px;
        width: auto;
        object-fit: contain;
      }
    }
  }
}

// modal
.modal {
  .modal-dialog {
    &.upgradeAccountModal {
      .companyLogo {
        height: 50px;
        display: block;
        margin: auto;
      }
    }

    .joinProfessonals {
      margin: 30px 0 10px;
      background-color: $white;
      padding: 25px;
      border-radius: $radius;
      text-align: center;

      h2 {
        font-size: 18px;
        margin-bottom: 8px;
        color: $black;
        font-weight: 600;
      }

      p {
        font-size: 14px;
        color: rgba($color: $black, $alpha: 0.6);
        margin-bottom: 0;
        line-height: 1.5;
      }

      strong {
        color: $black;
        font-weight: 500;
      }
    }

    &.uploadModal {
      .modal-content {
        .modal-body {
          padding: 0 !important;
        }
      }
    }
  }

  .modal-content {
    background-color: #f4f7fe;

    .modal-header,
    .modal-footer {
      background-color: transparent;
    }

    .modal-header {
      border-bottom: 1px solid #e0e0e0;
      padding: 20px;

      .modal-title {
        color: $black;
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 0.3px;
        flex: auto;
        // line-height: 1;
      }

      .close {
        padding: 0 !important;
        opacity: 1 !important;
        margin: 0;
        position: absolute;
        right: 20px;
        top: 30px;

        span {
          color: $black;
          font-size: 20px;
          font-weight: 400;
          display: inherit;
          line-height: 0;
        }
      }
    }

    .modal-footer {
      padding-top: 10px;
      padding-bottom: 30px;
      padding-bottom: 40px;

      button {
        &:last-child {
          margin-left: 15px;
        }
      }
    }

    .modal-body {
      padding: 15px !important;

      &.termCondition {
        line-height: 1.8;

        h4 {
          font-size: 20px;
          margin: 0 0 20px;
          text-align: center;
          font-weight: 600;
        }

        text-align: justify;

        strong,
        b {
          font-weight: 600 !important;
        }
      }
    }
  }

  .modal-footer {
    padding: 10px 12px 20px;
    border-top: 0;
    flex-wrap: nowrap;
    justify-content: center;

    button {
      width: calc(min(100% - 0.5rem, 200px));
      padding-inline: 10px;
    }
  }
}

.membersWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $borderColor;
  padding-top: 12px;
  margin-bottom: 10px;
  margin-top: 10px;
  span {
    font-weight: 500;
  }
}

.PermissionsList {
  h2 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  label {
    margin-bottom: 10px;
  }
}

.teamMembers {
  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.teamsAccordian {
  border: 1px solid rgba($primary-rgb, 0.2);
  border-radius: 6px;
  &:not(:first-child) {
    margin-top: 15px;
  }
  .teamsHead {
    padding: 10px;
    background-color: rgba($primary-rgb, 0.1);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-weight: 600;
      flex: 1;
    }
    button {
      svg {
        width: 18px;
        height: 18px;
      }
    }
    i {
      margin-left: 10px;
    }
  }
  .teamsBody {
    border-top: 1px solid rgba($primary-rgb, 0.2);
    padding: 10px;
    max-height: 480px;
    overflow-y: auto;
    h2 {
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 600;
      &:not(:first-child) {
        border-top: 1px dashed $borderColor;
        padding-top: 20px;
        margin-top: 15px;
      }
    }
    ul {
      li {
        padding-top: 10px;
        padding-bottom: 15px;
        &:not(:last-child) {
          border-bottom: 1px solid $borderColor;
          margin-bottom: 10px;
        }
        h3 {
          margin-right: 0px;
          margin-bottom: 15px;
          text-transform: capitalize;
          font-weight: 600;
          font-size: 14px;
          span {
            font-size: 12px;
            color: rgba($black, 0.8);
            margin-left: 5px;
          }
        }
        label {
          font-weight: 400;
          margin-bottom: 10px;
        }
      }
    }
  }
}

// order dashboard
.signingWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-right: 10px;
  margin-bottom: 25px;

  .signingUser {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    padding: 10px;
    border-radius: $radius;
    border: 1px solid $borderColor;

    .phoneIcon {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $secondary;
      border-radius: 50px;
      background-color: $secondary;
      color: $white;
      margin-right: 10px;
    }

    .content {
      span {
        font-weight: 500;
        font-size: 13px;
      }

      a {
        margin-top: 1px;
        display: block;
        font-weight: 500;
        font-size: 13px;
      }
    }
  }
}

.SigningAvailability {
  p {
    color: rgba($color: $black, $alpha: 0.9);

    strong {
      font-weight: 600;
      color: $black;
    }
  }
}

.signingCompanyInfo {
  margin-bottom: 20px;
  text-align: center;

  p {
    margin-bottom: 0px;
    font-size: 14px;
  }
}

.scheduledTime {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  label {
    margin: 0;
    margin-right: 5px;
  }

  h6 {
    font-weight: 600;
    color: $black;
    margin-bottom: 0;
  }
}

.signingAppointment {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  .custom-control {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.signerLocation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .locationDetails {
    // width: 80%;

    h6 {
      font-weight: 600;
      line-height: 1.4;
      font-size: 12px;
      cursor: pointer;

      &:hover {
        color: $primary;
      }
    }
  }
}

.locationLinks {
  display: flex;

  .form-check-radio {
    margin: 0;

    &:last-child {
      margin-left: 8px;
    }

    .form-check-label {
      padding: 0;

      .maps {
        width: 24px;
        height: 24px;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        margin-left: 0px;
        border: 1px solid $borderColor;

        img {
          height: 12px;
        }
      }

      input:checked ~ .maps {
        border-color: $primary;
      }
    }
  }
}

// expenses
.expensesList {
  li {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid $borderColor;
    padding: 15px;
    border-radius: $radius;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    .ExpenseItem {
      font-size: 12px;
      flex: 1;
      margin-right: 10px;

      span {
        font-weight: 400;
        display: flex;
      }

      button {
        line-height: 0;
      }

      h6 {
        font-weight: 600;
        margin-top: 4px;
        margin-bottom: 0;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 1.4;
        display: -webkit-box;

        a {
          color: $primary;
        }
      }
    }
  }
}

.customList {
  li {
    border: 0;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.3px;

    &:nth-child(odd) {
      background-color: #f8f9fd;
    }

    .amount {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      span {
        font-weight: 400;
        color: rgba($color: $black, $alpha: 0.7);
      }

      input {
        height: auto;
      }
    }
  }
}

.noataryAgentList {
  margin: 0;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    font-weight: 600;
    font-size: 12px;

    &:nth-child(even) {
      background: #f8f9fd;
    }

    .value {
      width: 100%;
      max-width: 150px;
      text-align: right;
      font-size: 12px;
      color: rgba($black, 0.8);
      font-weight: 400;

      .input-group {
        height: 30px;
        // width: 200px;
        margin-left: auto;

        input,
        button {
          height: 28px;
        }

        button {
          font-size: 12px !important;
          font-weight: 400;
          padding: 0 10px !important;
        }
      }
    }

    button {
      font-size: 12px;
      text-align: right;
    }
  }
}

.agentSelect {
  padding: 0 20px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;

  .form-group {
    flex: auto;
    margin-right: 10px;
    margin-bottom: 0;

    label {
      margin-top: 10px;
    }
  }
}

// profile page

.profileInfo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  z-index: 99;
  margin-top: 15px;
  padding-inline: 20px;

  .uploadProfile {
    margin: -70px 0 10px !important;
    box-shadow: none;
  }
}

.coverImageWrapper {
  height: 200px;
  width: 100%;
  position: relative;
  background-color: $black;
  z-index: 1;

  .coverImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0;
  }

  .uploadUserInfo {
    opacity: 0;
    pointer-events: none;
    transition: 0.5s ease-in-out;
  }

  &:hover {
    .uploadUserInfo {
      pointer-events: all;
      opacity: 1;
    }
  }

  &.notUpdated {
    .uploadUserInfo {
      opacity: 1;
      pointer-events: all;
    }
  }
}

// manage review links
.reviewLinks {
  ul {
    li {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      border-bottom: 1px dashed $borderColor;
      padding: 20px 0;

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }

      .socialIcon {
        width: 48px;
        height: 48px;
        border: 1px solid $borderColor;
        padding: 10px;

        label {
          width: 100%;
          height: 100%;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .socialLinks {
        flex: 1;

        .form-control {
          border-radius: 0 !important;
        }

        .input-group {
          border-radius: 0 !important;
          margin-left: -1px;

          &:focus-within {
            border-color: #dbdbdb !important;
          }

          .input-group-prepend {
            background-color: transparent;
            padding-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .socialAction {
        button {
          padding: 10px;
        }
      }
    }
  }
}

// agent my credentials
.verificationWrapper {
  padding: 30px 20px;
  text-align: center;

  h3 {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-bottom: 0px;
    }
  }

  p {
    color: $black;
    margin-top: 6px;
    margin-bottom: 0;
  }
}

.verifiedCredentials {
  border: none;

  li {
    border: none;
    font-size: 12px;
    font-weight: 600;
    padding: 0;
    font-size: 14px;

    &:nth-child(even) {
      background-color: #f8f9fd;
    }

    .accordianHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $borderColor;
      padding: 15px;

      img {
        margin-right: 8px;
        height: 24px;
      }

      div {
        display: flex;
        align-items: center;
      }
    }

    .accordionBody {
      padding: 15px;
      border-bottom: 1px solid $borderColor;
    }

    table {
      td {
        .credentialImage {
          color: $primary;
          cursor: pointer;
          @include flexBox(center, flex-start, nowrap);

          .imageWrap {
            width: 18px;
            height: 18px;
            margin-right: 5px;
            display: block;

            img {
              width: 100%;
              height: 100%;

              &.pdf {
                height: 18px;
              }
            }
          }

          span {
            flex: 1;
          }
        }
      }
    }
  }

  .btn {
    font-weight: 400;
    font-size: 12px;
  }

  .deletePreview {
    top: -9px;

    i {
      color: $form-error !important;
    }
  }
}

.verifiedCredentials li:last-child {
  border-bottom: 0;
}

.credentialImage {
  color: $primary;
  cursor: pointer;
  @include flexBox(center, flex-start, nowrap);
  width: 150px;

  .imageWrap {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    display: block;

    img {
      width: 100%;
      height: 100%;

      &.pdf {
        height: 18px;
      }
    }
  }

  span {
    flex: 1;
    font-size: 12px;
    font-weight: 500;
  }
}

.insuranceDetail {
  margin-bottom: 2px;
  border-bottom: 1px dashed rgba($black, 0.1);

  &:last-child {
    border-bottom: none;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      border-bottom: 0;
    }

    span {
      margin-left: 10px;
    }
  }

  i {
    font-size: 14px;
    vertical-align: -1px;
    margin-right: 6px;
    width: unset;
    color: $primary;
  }
}

// barcode
.barcodeWrap {
  // padding-bottom: 20px;

  .barcode {
    position: relative;
    text-align: center;
    height: 310px;
    width: 310px;
    margin: 20px auto 0;
    border: 2px solid $secondary;

    &::before {
      content: "";
      position: absolute;
      top: 18px;
      left: -8px;
      width: calc(100% + 16px);
      height: calc(100% - 36px);
      background-color: #f4f7fe;
    }

    &::after {
      content: "";
      position: absolute;
      left: 18px;
      top: -8px;
      height: calc(100% + 16px);
      width: calc(100% - 36px);
      background-color: #f4f7fe;
    }

    canvas {
      top: 5%;
      height: 90% !important;
      width: 90% !important;
      z-index: 9;
      position: relative;
      border-radius: 8px;
      border: 1px solid #e5e5e5;
    }
  }

  h3 {
    text-transform: lowercase;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary;
    font-weight: 500;
    margin-top: 40px;
    margin-bottom: 0px;
    text-align: center;

    span {
      white-space: nowrap;
      width: 300px;
      display: block;
      overflow: hidden;
      margin-right: 5px;
      pointer-events: none;
    }
  }

  button {
    max-width: 180px;
    width: 100%;
    margin-left: 15px !important;

    &:first-child {
      margin-left: 0 !important;
    }

    img {
      margin-left: 10px;
      display: none;

      &.shareIconBlue {
        display: inline-block;
      }
    }

    &:hover {
      img {
        display: inline-block;

        &.shareIconBlue {
          display: none;
        }
      }
    }

    &:focus {
      img {
        display: none;

        &.shareIconBlue {
          display: inline-block;
        }
      }
    }
  }
}

// agent schedule tab
.scheduleWrapper {
  background-color: #e3faff;
  padding: 30px 20px;
  text-align: center;
  margin-bottom: 20px;
  border-radius: $radius;

  h2 {
    font-size: 16px;
    color: $black;
    font-weight: 600;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    color: $black;
  }
}

.workingDays {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .btn {
    width: 55px;
    height: 55px;
    border-radius: 50px;
    padding: 0;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 500;
    background-color: $white !important;
    border: 1px solid $secondary !important;
    color: $secondary !important;
    margin-left: 0;
    margin-right: 10px !important;
    margin-bottom: 10px !important;

    &:last-child {
      margin-right: 0;
    }

    &.selected,
    &:hover {
      color: $white !important;
      border-color: $secondary !important;
      background-color: $secondary !important;
    }
  }
}

.standardFeeWrap {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;

  .form-group {
    width: 50%;
    padding-right: 8px;

    &:nth-child(even) {
      padding-right: 0;
      padding-left: 8px;
    }
  }
}

.productTypelist {
  position: relative;
  margin-bottom: 15px;

  .productWrap {
    border: 1px solid #dbdbdb !important;
    background-color: $white;
    position: relative;
    font-size: 15px;
    padding: 0 10px;
    border-radius: 8px !important;
    padding-top: 16px;
    margin: 0;
    min-height: 48px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .selectedValue {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .valueChip {
        background-color: rgba($primary-rgb, 0.1);
        font-size: 11px;
        border-radius: 8px;
        font-weight: 500;
        margin-right: 8px;
        margin-top: 2px;
        margin-bottom: 5px;
        padding: 3px 6px;
        img {
          cursor: pointer;
          height: 14px;
          margin-left: 5px;
        }
      }
    }

    h3 {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
    }
  }

  label {
    position: absolute;
    top: 3px;
    font-size: 12px;
    color: #767676;
  }

  .typeList {
    position: absolute;
    top: 47px;
    background-color: $white;
    border: 1px solid $borderColor;
    padding: 10px;
    z-index: 99;
    width: calc(100% - 100px);
    display: none;
    // max-height: 250px;
    // overflow-y: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    &.show {
      display: block;
    }

    ul {
      display: none;

      &.frontView {
        display: block;
        visibility: visible;
        height: auto;
        overflow: hidden;

        &.moveLeft {
          visibility: hidden;
          height: 0;
          overflow: hidden;
          z-index: -1;
        }
      }

      &.innerView {
        &.rightSide {
          display: block;
        }
      }

      li {
        cursor: pointer;
        padding: 10px 0;
        border-bottom: 1px dashed $borderColor;
        font-size: 13px;

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
        &.backBtn {
          color: $black;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }

  &.showFullWidth {
    .typeList {
      width: max-content;
    }
    .productWrap {
      min-height: 40px !important;
      padding-top: 0 !important;
      width: 200px;
      border: none !important;
      box-shadow: 0 0 10px 0 #69b5ed29;
    }
  }
}

.leaveDescription {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

// bank info tab

// create order
.ClosingWrapper {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;

  &.multipleRow {
    margin-bottom: 20px;
    &:not(:first-child) {
      border-top: 1px dashed $borderColor;
      padding-top: 20px;
    }
  }

  .addMoreClosing {
    width: 100px;
    text-align: right;
  }
}

// notifications
.notificationWrapper {
  margin-bottom: 30px;

  h6 {
    display: flex;
    align-items: center;
    font-weight: 500;

    .badge {
      margin-left: 6px;
      font-size: 10px;
      margin-bottom: 0;
      font-weight: 600;
      padding: 0px !important;
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #09cff9 !important;
      border-color: #09cff9 !important;
    }
  }

  ul {
    overflow: hidden;
    border: 1px solid $borderColor;
    background-color: $white;
    border-radius: $radius;
    margin-bottom: 20px;

    li {
      position: relative;
      padding: 15px;
      cursor: pointer;

      .dot {
        width: 8px;
        height: 8px;
        background-color: #09cff9;
        border-radius: 50%;
        animation: blink 1s infinite;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
      }

      &:not(:last-child) {
        border-bottom: 1px solid $borderColor;
      }

      .notificationContent,
      .notificationHeader {
        padding-left: 10px;
      }

      .notificationHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;

        h3 {
          margin-bottom: 0;
          font-size: 12px;
          font-weight: 600;
          color: $black;
        }

        span {
          font-size: 12px;
        }
      }

      p {
        font-size: 12px;
        margin-bottom: 0;
        color: rgba($color: $black, $alpha: 0.7);
      }

      &.notificationRead {
        background-color: #f5f5f5;
      }
    }
  }
}

// Notarial Log
.infoCard {
  border: none !important;
  box-shadow: 0 0px 10px 3px rgba($color: #69b5ed29, $alpha: 0.1);

  .card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .infoContent {
      flex: 1;
      width: 100%;

      span {
        font-size: 12px;
        color: $grey;
        margin-bottom: 5px;
        display: block;
      }

      h4 {
        font-size: 16px;
        font-weight: 600;
        margin: 0;

        &.danger {
          color: #ff8181;
        }

        &.warning {
          color: #ee7e3d;
        }

        &.success {
          color: #3aa36a;
        }
      }
    }

    .icon {
      background-color: rgba($color: $primary-rgb, $alpha: 0.1);
      border: 1px solid rgba($color: $primary-rgb, $alpha: 0.7);
      padding: 6px;
      width: 40px;
      height: 40px;
      margin-left: 20px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: $primary;
        width: 24px;
        height: 24px;
      }
    }
  }
}

// invoicing

.jobsIncluded {
  margin: 0 0 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  background-color: $white;
  border: 1px solid $borderColor;
  border-radius: $radius;
  list-style: none;

  li {
    width: 33.333%;

    &:nth-child(2) {
      text-align: center;
    }

    &:nth-child(3) {
      text-align: right;
    }

    span {
      display: block;
      color: $black;
      font-size: 14px;

      &:last-child {
        font-weight: 600;
      }
    }
  }
}

// income
.incomeListAgent {
  padding: 25px 15px 18px;
  background-color: $white;
  border: 1px solid $borderColor;
  border-radius: 10px;
  margin-top: 30px;

  .recordPayment {
    text-align: center;

    h4 {
      margin: 20px 0;
      font-size: 16px;

      span {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}

// subscribtion
.subscriptionPlanCard {
  background-color: $white;
  padding: 30px 15px;
  border-radius: $radius;
  border: 1px solid $borderColor;
  position: relative;
  height: 100%;

  .subscriptionTitle {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 10px;
    padding-top: 10px;
    color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-weight: 600;
      margin-left: 6px;
    }

    .currentPlan {
      background-color: #d9ffea;
      font-size: 12px;
      color: #3aa36a;
      font-weight: 400 !important;
      padding: 4px 8px;
      border-radius: 20px;
      margin-left: 5px;
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .priceWrap {
    text-align: center;
    color: $black;
    font-weight: 400;

    span {
      &.price {
        font-size: 40px;
        font-weight: 500;
      }

      .subMonth {
        font-size: 400px;
      }
    }
  }

  .subscriptionToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
  }

  .subscriptionContent {
    width: calc(min(100% - 0.5rem, 350px));
    margin: auto;

    &.free {
      margin-top: 42px;
    }

    .points {
      margin-bottom: 20px;
      border-bottom: 1px dashed $borderColor;
      padding-bottom: 15px;

      &:nth-last-of-type(2) {
        padding-bottom: 0;
        border-bottom: none;
        margin-bottom: 0;
      }

      h2 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 6px;
      }

      ul {
        padding-left: 0px;

        li {
          font-size: 14px;
          padding: 6px 0;
          color: rgba($black, 0.9);
          position: relative;
          display: flex;
          align-items: flex-start;

          div {
            line-height: 1.5;
            flex: auto;
          }

          span {
            font-weight: 600;
            font-style: italic;
          }

          img {
            margin-right: 10px;
            position: relative;
            top: 4px;
            height: 12px;
          }
        }
      }

      &.promoCard {
        border-bottom: none;

        ul {
          li {
            padding: 12px 0;

            &:not(:last-child) {
              border-bottom: 1px dashed $borderColor;
            }
          }
        }
      }
    }

    .btn {
      height: auto !important;
      font-size: 12px;

      margin-top: 30px;
    }
  }

  .subcriptionAction {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.requestAccess {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.selectedPlan {
  .card-body {
    h2 {
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 10px;

      span {
        font-weight: 500;
        margin-left: 5px;
      }
    }

    .plan {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    h6 {
      margin-bottom: 0;

      span {
        &:not(.planOption) {
          font-size: 30px;
          font-weight: 600;
          margin-top: 6px;
        }

        display: inline-block;
      }
    }
  }
}

.saveCardList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .CardDetail {
    width: 50%;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
}

.stripeWrapper {
  .StripeElement {
    background-color: $white;
  }
}

.completeKyc {
  text-align: center;
  background-color: #fff2f2;
  padding: 25px;
  border-radius: $radius;
  margin-bottom: 20px;

  h1 {
    font-size: 17px;
  }

  p {
    color: $black;
    width: 80%;
    margin: 20px auto 25px;
  }
  &.reKyc {
    display: flex;
    align-items: center;
    .kycContent {
      flex: 1;
    }
  }
}

// settings
.settingCard {
  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .card-body {
    padding: 0;

    ul {
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 15px;
        font-size: 12px;

        &:nth-child(even) {
          background-color: #f8f9fd;
        }
      }
    }
  }
}

.invoicingFrequency {
  padding: 20px;
  border: 1px solid $borderColor;
  margin-top: 20px;
  border-radius: $radius;

  h5 {
    font-size: 14px;
    font-weight: 500;
  }
}

.automationAccordian {
  border: 1px solid $borderColor;
  border-radius: $radius;
  overflow: hidden;

  .accordianHead {
    background-color: #f7f7f7;
    border-bottom: 1px solid $borderColor;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 14px;
    cursor: pointer;
  }
}

.automationList {
  li {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 10px;

    &:nth-child(even) {
      background-color: #f8f9fd;

      .custom-select-wrapper {
        &::after {
          right: 0;
          background-color: #f8f9fd;
        }
      }
    }

    .automationItem {
      // flex: 0 0 16%;
      flex: 1;
      font-size: 12px;
      margin-right: 20px;
      margin-bottom: 20px;

      label {
        display: block;
        color: $black;
      }

      span {
        font-weight: 600;
      }

      .form-control {
        height: auto;
        border: none;
        background-color: transparent;
        min-width: inherit;

        &:not(.status) {
          padding: 0 !important;
        }
      }

      .custom-select-wrapper {
        &::after {
          right: 0;
          top: 0;
        }
      }
    }
  }
}

.stripeWrapper {
  margin-top: 10px;
  margin-bottom: 30px;

  .StripeElement {
    border: 1px solid #eff0f6;
    background-color: #eff0f6;
    border-radius: 8px;
    height: 46px;
    display: flex;
    align-items: center;
    padding-left: 6px;
    padding-right: 2px;
  }

  .CardField-number {
    width: 140px !important;
  }
}

.stripeWrapper * {
  width: 100% !important;
}

// settings email customizations
.emailItem {
  margin-bottom: 20px;

  label {
    font-weight: 600;
    margin-bottom: 4px;

    button {
      font-size: 11px;
      padding-left: 4px;
    }
  }

  p {
    font-size: 12px;
    color: rgba($black, 0.9);
    margin-bottom: 8px;

    &:empty {
      display: none;
    }
  }
}

// profit loss
.profitLossCard {
  &.netIncome {
    .card-header {
      background-color: $white !important;
    }
  }

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: none;

    .card-title {
      margin-bottom: 0;
      font-size: 14px;
    }

    button {
      img {
        height: 7px;
      }

      &.show {
        transform: rotatex(180deg);
      }
    }
  }

  .profitCollapse {
    border-top: 1px solid $borderColor;
  }

  .income {
    font-weight: 600 !important;
    color: $black !important;
    font-size: 14px !important;
  }

  .card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $borderColor;
    padding: 15px;

    p {
      margin-bottom: 0;
    }
  }
}

.profitLossReport {
  li {
    padding: 15px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    color: $black;

    &:nth-child(even) {
      background-color: #f8f9fd;
    }

    span {
      color: $black;
      // font-weight: 600;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
}

.assingedAgent {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .customSelect {
    flex: 1;
    margin-right: 15px;
  }
}

.appointmentInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.alreadyAssinged {
  margin-top: 15px;
  margin-bottom: 20px;

  h3 {
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 14px;
    color: $black;
  }

  ul {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    overflow-x: auto;

    &:empty {
      display: none;
    }

    li {
      background-color: $white;
      border: 1px solid #ddd;
      padding: 10px;
      border-radius: 6px;
      display: flex;
      margin-right: 15px !important;

      div {
        white-space: nowrap;

        &:nth-child(2) {
          margin: 0 20px;
        }

        &:first-child {
          padding-top: 0;
          margin-left: 0px;
        }

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }

        span {
          &:first-child {
            font-size: 12px;
            display: block;
            color: rgba(0, 0, 0, 0.7);
            font-weight: 400;
          }

          &:last-child {
            color: $black;
            font-weight: 600;
            font-size: 11px;
          }
        }
      }
    }
  }

  p {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: $grey;

    span {
      color: $form-error;
      font-size: 14px;
      margin-right: 5px;
      position: relative;
      top: 2px;
      height: auto;
    }
  }
}

.assignWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  > div {
    flex: 1 1 auto;
  }

  .assignWrap {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    h4 {
      font-size: 14px;
      margin: 0;
      font-weight: 500;
      margin-right: 10px;
    }
  }

  .automationAction {
    text-align: right;

    button {
      font-size: 14px;
    }
  }
}

.modal {
  .agentDetail {
    .modal-content {
      .modal-body {
        padding: 0 !important;
      }
    }
  }
}

.aboutAgent {
  display: flex;
  align-items: center;
  padding: 30px;

  .uploadProfile {
    margin: 0 20px 0 0 !important;
  }

  .agentInfo {
    flex: 1;

    h3 {
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      margin-bottom: 0;

      .badge {
        margin: 0;
        margin-left: 10px;
      }
    }

    .description {
      margin: 8px 0 !important;

      p {
        color: $black;
        margin-bottom: 0;
      }
    }

    .userRatingProfile {
      margin: 10px 0 0;

      span {
        color: #ee7e3d;
        font-size: 14px;
      }

      button {
        text-transform: capitalize;
        font-size: 14px;
      }
    }
  }
}

.agentInfoList {
  background: $white;
  padding: 0 5px;
  border-radius: 8px;

  li {
    flex: 1;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding: 10px;

    span {
      font-weight: 600;
    }
  }
}

.commentWrap {
  background-color: $white;
  padding: 20px;
  margin: 20px;
  border-radius: $radius;

  &:last-child {
    padding-bottom: 0;
  }

  .date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #2c4199;
    font-size: 14px;
    font-weight: 600;

    span {
      font-size: 14px;

      &:last-child {
        color: #ee7e3d;
      }
    }
  }

  p {
    color: rgba($black, 0.5);
    margin-top: 4px;
    margin-bottom: 0;
  }
}

.credentialsList {
  overflow: auto;
  max-height: 300px;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background-color: #f5f5f5;

    &:nth-child(even) {
      background-color: $white;
    }

    img {
      height: 20px;
      margin-right: 5px;
    }

    button,
    span {
      font-size: 12px;
      text-transform: capitalize;
    }
  }
}

// graph
// .barGraphWrap{
//     height: 500px;
//     min-height: inherit !important;
//     > div{
//         min-height: inherit !important;
//     }
//     .apexcharts-canvas{
//         height: 100% !important;
//         svg{
//             height: 100% !important;
//             foreignObject{
//                 height: 100% !important;
//             }
//         }
//     }
// }
.apexcharts-legend {
  justify-content: flex-end !important;
}

// .apexcharts-canvas {
//   touch-action: pan-y; /* or 'auto' or 'pan-x pan-y' as needed */
// }

.graphWrap {
  padding: 20px 15px;
  border: 1px solid $borderColor;
  border-radius: 10px;
  background-color: $white;
  margin-bottom: 25px;

  h1 {
    font-size: 14px;
    font-weight: 600;
    color: $black;
  }

  h6 {
    margin-bottom: 0;
    text-align: center;
    font-weight: 600;
  }

  &.noData {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      margin-bottom: 20px;
    }
  }
}

.apexcharts-canvas {
  svg {
    touch-action: auto;
  }
}

// client
.clientAdditionalContacts {
  background-color: $white;
  padding: 15px;
  border-radius: $radius;
  border: 1px solid $borderColor;
  margin: 0 0 15px;

  button {
    margin: auto;
    display: block;
  }
}

.clientContactInfo {
  font-size: 12px;

  h6 {
    font-weight: 600;
    color: $black;
  }

  .infoWrap {
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    label {
      display: block;
      margin-bottom: 5px;
    }

    button {
      font-size: 12px;
      text-align: left;
      padding: 0;
    }
  }
}

.aboutCompany {
  text-align: center;
  position: sticky;
  top: 80px;

  .card-body {
    padding: 30px;

    .action {
      margin-top: 15px;

      button {
        height: 34px;
        padding: 0px 20px;
        font-size: 12px;
        border-width: 1px !important;
        margin-inline: 6px;
        line-height: 1.5;

        &.btn-outline-success {
          background-color: #d9ffea;
        }

        // &.btn-outline-danger{
        //     background-color: #FFF2F2;
        // }
      }
    }
  }

  .companyImage {
    width: 100px;
    height: 100px;
    margin: 0 auto 30px;
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 0 0 2px 10px rgba(76, 150, 206, 0.2);

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  h5 {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 6px;
    text-align: center;
  }

  p {
    font-size: 12px;

    &:empty {
      display: none;
    }
  }

  &.signingClientDetails {
    text-align: left;
    position: unset;

    .card-body {
      display: flex;
      align-items: center;

      .companyImage {
        margin: 0;
      }

      .clientContent {
        flex: 1;
        margin-left: 20px;

        h5 {
          text-align: left;
        }

        p {
          margin-bottom: 6px;
        }

        ul {
          li {
            padding: 2px 0;

            img {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  &.agentDetails {
    text-align: left;

    ul {
      li {
        display: flex;
        align-items: center;
        padding: 4px 0;
        font-size: 12px;

        span {
          margin-left: 10px;
        }
      }
    }
  }
}

.signingOfferButton {
  text-align: center;
}

.CompanyStats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f9fd;
  padding: 15px;
  border-radius: $radius;

  h6 {
    font-size: 12px;
    font-weight: 300;
  }

  p {
    color: $black;
    font-weight: 500;
    margin-bottom: 0;
  }

  .icon {
    width: 40px;
    height: 40px;
    background-color: $white;
    border-radius: 50px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.weekName {
  @include flexBox(center, flex-start, wrap);
  list-style: none;
  padding: 0;

  li {
    width: 22px;
    height: 22px;
    border: 1px solid $primary;
    color: $primary;
    border-radius: 50px;
    font-size: 11px;
    text-align: center;
    line-height: 20px;
    margin-right: 10px;
    margin-bottom: 10px;

    &.selected {
      background-color: $secondary;
      border-color: $secondary;
      color: $white;
    }
  }
}

.scheduleTime {
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
}

.standardFee {
  @include flexBox(center, flex-start, wrap);

  list-style: none;
  padding: 0;
  width: 100%;
  margin-bottom: 0;

  li {
    padding: 15px 20px 15px 0;
    font-size: 12px;
    // flex: 1 0 130px;
    flex-grow: 0;

    span {
      display: block;
      font-weight: 600;
      margin-top: 5px;
    }
  }
}

.agentCredentials {
  li {
    padding: 15px;

    &:nth-child(even) {
      background-color: #f8f9fd;
    }

    .credentialItem {
      font-size: 12px;
      @include flexBox(center, space-between, nowrap);
    }

    // .credentialsInfo {
    //   font-size: 12px;
    // }

    .otherInfo {
      display: block;
      text-align: right;
      color: #767676;
    }

    .fileInfo {
      @include flexBox(center, flex-end, nowrap);
      text-align: right;
      color: $primary;
      cursor: pointer;
      max-width: 200px;

      img {
        margin-right: 5px;
      }

      span {
        @include lineClamp(1);
      }
    }
  }
}

.BankSettingCard {
  padding: 30px;
}

.addedBankAccount {
  .card-header {
    display: flex;
    align-items: center;

    .card-title {
      text-transform: capitalize;
    }

    button {
      width: 18px !important;
      height: 18px !important;
      border-radius: 50px;
      padding: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $white;
      font-size: 10px;
      margin-left: 4px;

      i {
        margin: 0;
      }
    }
  }

  .card-body {
    p {
      font-size: 14px;
      color: $black;
      font-weight: 600;
      margin-bottom: 2px;

      span {
        margin-right: 5px;
      }
    }
  }
}

.noAccount {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.noataryAgent {
  margin-top: 30px;
}

.activityLog {
  li {
    font-size: 12px;
    padding: 6px 0;
    font-weight: 400;

    button {
      padding: 0;
      font-size: 12px;
      text-align: left;
    }
  }
}

// signing company
.uploadStatusWrap {
  margin-bottom: 15px;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;

    &:nth-child(odd) {
      background-color: $white;
    }

    .uploadItem {
      flex: 1;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      .statusIcon {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        border: 1px solid $borderColor;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        margin-right: 10px;
      }

      h6 {
        margin-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
      }

      p {
        margin-bottom: 2px;
        font-size: 12px;
      }

      &.success {
        .statusIcon {
          background-color: #d9ffea;
          border: 1px solid #3aa36a;
        }
      }

      &.primary {
        .statusIcon {
          background-color: #e4f4ff;
          border: 1px solid #4c96ce;
        }
      }

      &.warning {
        .statusIcon {
          background-color: #fef1e9;
          border: 1px solid #ee7e3d;
        }

        .text-warning {
          color: #ee7e3d !important;
        }
      }

      &.danger {
        .statusIcon {
          background-color: #fff2f2;
          border: 1px solid #ff7979;
        }

        .text-warning {
          color: #ff7979;
        }
      }

      .text-success {
        color: #3aa36a !important;
      }
    }

    .statusIndicator {
      img {
        height: 22px;
      }
    }
  }
}

.referralLink {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  a {
    padding: 0 10px;
    border: 1px solid $borderColor;
    height: 48px;
    border-radius: $radius;
    white-space: nowrap;
    line-height: 48px;
    display: inline-block;
    overflow: hidden;
    max-width: 400px;
    width: 100%;
    margin-right: 15px;
    // margin-bottom: 15px;
  }

  .btn {
    white-space: nowrap;
  }
}

.jobsCard {
  margin-bottom: 10px;

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.instructionWrap {
  border: 1px solid $borderColor;
  border-radius: $radius;

  .instructionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid $borderColor;
    background-color: #f7f7f7;

    h2 {
      font-size: 16px;
      margin: 0;
    }
  }

  .instructionContent {
    padding: 10px;

    span {
      color: $grey;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
    }

    .intruContent {
      font-weight: 500;
      margin-bottom: 0px;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;

      p {
        margin: 0;
      }
    }

    h5 {
      font-size: 12px;
      font-weight: 600;
    }

    button {
      margin-top: 10px;
      font-size: 14px;
      width: 100%;
    }
  }
}

.read-more-less {
  ul,
  ol {
    padding-left: 40px; /* Default padding for indent */
    margin-bottom: 16px; /* Default margin between list items */
  }
  ul {
    list-style: initial;
  }
  ol {
    list-style: decimal;
  }
  li {
    margin-bottom: 8px; /* Optional: Adds spacing between list items */
  }
}

.rating {
  max-width: inherit;
  display: flex;

  .star-ratings {
    display: flex !important;
    align-items: center;

    .star-container {
      padding-right: 0px !important;
      padding-left: 2px !important;

      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}

// agent about page
.aboutWrapper {
  max-width: 600px;
  width: 100%;
  margin: auto;
  margin-bottom: 20px;

  [class*="col-"] {
    padding: 0;
  }
}

.agentCoverBg {
  height: 240px;
  z-index: 1;
  position: relative;
  padding-top: 10px;
  text-align: center;

  img {
    width: auto;
    height: 100%;
    object-fit: contain;
  }
}

.agentInnerWrap {
  padding: 0 15px 20px;
  background-color: $white;
  z-index: 1;
  position: relative;
}

.agentAbout {
  padding: 15px 0px;

  .agentImage {
    background-color: $white;
    padding: 5px;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    margin-top: -65px;
    overflow: hidden;
    z-index: 9;

    img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .agentContent {
    padding-top: 10px;

    h1 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 4px;
    }
    h3 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    h2 {
      font-size: 18px;
      margin: 10px 0;
      font-weight: 500;
    }

    p {
      font-size: 14px;
      color: $grey;
      margin-bottom: 10px;
    }
  }

  .infoAgent {
    display: flex;
    justify-content: space-between;
  }
}

.bookAppointment {
  box-shadow: none;
  .card-body {
    padding: 0;
  }
  ul {
    li {
      flex: 100%;
      margin-bottom: 0;
      padding: 15px 0;
      border-bottom: 1px dashed $borderColor;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border: none;
      }
      .agentIcon {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $black;
        margin-right: 10px;
        svg {
          stroke: white;
          width: 16px;
          height: 16px;
          &.phone {
            fill: none;
          }
          &.linkdin {
            fill: none;
            path {
              fill: transparent;
              stroke: $white;
            }
            circle {
              stroke: $white;
            }
          }
        }
      }
      a {
        display: flex;
        align-items: center;
        color: $black;
        font-weight: 500;
        span {
          display: block;
          color: $grey;
          font-size: 12px;
          font-weight: 400;
          margin-top: 5px;
        }
      }
    }
  }
}

.aboutBarCode {
  max-width: 180px;
  max-width: 180px;
  margin: auto;

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
  p {
    width: 200px;
    font-size: 13px;
  }
}

.socialReview {
  h4 {
    font-size: 16px;
    font-weight: 500;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      width: 30px;
      height: 30px;
      padding: 7px;
      border-radius: 50px;
      background-color: $primary;
      margin-inline: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: auto;
        margin-bottom: 0;
      }
    }
  }
}

.aboutContactInfo {
  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;

    .icon {
      width: 34px;
      height: 34px;
      background-color: $primary;
      border-radius: 50px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    div:not(.icon) {
      padding-left: 10px;

      span {
        display: block;
        font-size: 12px;
        color: grey;
      }
    }

    a,
    button {
      color: $black !important;
      padding: 0;
      text-transform: capitalize;
      font-size: 14px !important;
      font-weight: 500;
    }
  }
}

.agentCredentialsList {
  @include flexBox(center, flex-start, wrap);
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    padding: 8px;
    font-size: 12px;
    font-weight: 500;
    background-color: $borderColor;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: $radius;

    img {
      margin-right: 5px;
      height: 24px;
    }
  }
}

.barContainer {
  margin-top: auto;
  background-color: $white;
  height: 100%;
  // padding: 20px;
  // padding-top: 80px;
  text-align: center;

  img {
    height: 50px;
    margin-bottom: 50px;
  }

  p {
    font-size: 12px;
    width: 200px;
  }

  .barCodeWrap {
    p {
      font-weight: 600;
      margin-bottom: 0px;
    }
  }
}

.cardTableTitle {
  flex: 1;

  label {
    color: $grey;
    display: block;
    font-size: 12px;
    margin-bottom: 0;
  }

  span {
    font-size: 12px;
    font-weight: 600;
  }
}

.counterOfferWrap {
  max-width: 700px;
  width: 100%;
  background-color: $white;
  border-radius: $radius;
  padding: 20px;
  margin: auto;
  margin-top: 20px;
}

.responsiveTitle {
  display: none;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 20px;

  .backBtn {
    padding-left: 0;
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid $borderColor !important;
  }

  h2 {
    font-size: 16px;
    color: $black;
    font-weight: 600;
    margin: 0;

    span {
      font-size: 12px;
      font-weight: 400;
      display: block;
      margin-top: 2px;
    }
  }

  .rightSide {
    @include flexBox(center, flex-end, wrap);
    button {
      font-size: 12px;
      height: auto;
      font-weight: 600;
      margin-bottom: 5px;
    }
    .dropdown {
      margin-left: 10px;
    }
  }
}

.publicPageTitle {
  margin-bottom: 20px;

  h2 {
    font-size: 16px;
    color: $black;
    font-weight: 600;
    margin: 0;

    span {
      font-size: 12px;
      font-weight: 400;
      display: block;
      margin-top: 2px;
    }
  }
}

.mobileTitle,
.clearButton {
  display: none;
}

.InputWithButton {
  display: flex;

  .form-group {
    flex: auto;
  }

  button {
    margin-left: 15px;
    font-size: 14px;
    white-space: nowrap;
  }
}

.dSync_icon {
  width: 28px;
  height: 28px;
  margin-left: 10px;
  cursor: pointer;
  svg {
    fill: $primary;
  }
}

// Responsive card Table
.responsiveTableCard {
  border: 1px solid $borderColor;
  height: 100%;
  margin-bottom: 0 !important;

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-color: $borderColor !important;

    button {
      + button {
        margin-left: 10px;
      }
    }
  }

  .tableUserInfo {
    width: auto;
    flex: 1;

    .userContent {
      span {
        font-size: 12px !important;
      }
    }
  }

  .btnConfirm {
    font-size: 12px;
    height: auto;
  }

  .cardInfo {
    li {
      padding: 15px;
      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
        border-bottom: 1px dashed $borderColor;
      }

      .dataWrap {
        flex: 1;
        font-size: 12px;

        &:nth-child(2) {
          padding-left: 15px;
        }
      }
    }
  }

  .docStatus {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    .actionBtn {
      margin-right: 10px !important;
      margin-bottom: 0 !important;
      text-transform: capitalize;
      font-size: 12px;
    }
  }

  .customSelectWrap {
    margin: auto;
    width: max-content;

    select {
      height: 28px;
      padding-right: 25px !important;
      padding-left: 15px !important;
      text-align: center;
    }

    .downArrow {
      top: 4px;
    }
  }
}

.dataList {
  width: 250px;
  border: 1px solid $borderColor;
  margin-bottom: 20px;
  border-radius: $radius;

  &:last-child {
    margin-bottom: 10px !important;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dashed $borderColor;
    padding: 8px;
    font-size: 12px !important;

    &:last-child {
      border-bottom: none;
    }

    label {
      font-size: 12px !important;
      margin-bottom: 0 !important;
    }

    > div {
      width: 150px;
      text-align: right;
    }
  }
}

.signerPopover {
  .popover-body {
    max-height: 400px;
    overflow-y: auto;
  }
}

.instructionCard {
  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      font-size: 14px;
    }
  }
}

.popover {
  max-width: inherit;

  .popover-header {
    padding: 8px;
    border-bottom: 1px solid rgba($black, 0.08);
    margin-top: 0;
    font-weight: 600;
    font-size: 12px;
    color: $black !important;
  }

  .popover-body {
    p {
      font-size: 14px;
      margin-bottom: 6px;
      color: $grey;
    }

    p:last-child {
      margin-bottom: 2px;
    }
  }
}

.pdfView {
  height: calc(100% - 150px);
  width: 100%;
  position: sticky;
  top: 85px;

  .signerPopover {
    .popover-body {
      max-height: 400px;
      overflow-y: auto;
    }
  }

  .popover {
    max-width: inherit;

    .popover-header {
      padding: 8px;
      border-bottom: 1px solid rgba($black, 0.08);
      margin-top: 0;
      font-weight: 600;
      font-size: 12px;
      color: $black !important;
    }

    .popover-body {
      p {
        font-size: 14px;
        margin-bottom: 6px;
        color: $grey;
      }

      p:last-child {
        margin-bottom: 2px;
      }
    }
  }

  iframe {
    height: 100%;
    width: 100%;
  }
}

.pdfContent {
  background-color: $white;
  padding: 20px;
  border-radius: $radius;
  position: sticky;
  top: 80px;
}

.uiDesign {
  border: 1px solid $borderColor;
  border-radius: $radius;
  cursor: pointer;

  h1 {
    border-bottom: 1px solid $borderColor;
    margin-bottom: 15px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    background-color: #f5f5f5;

    ~ label {
      padding: 0 10px 10px 10px;
      width: 100%;
      height: 200px;
      overflow: auto;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: left top;
      }
    }
  }
}

// social media
.socialLoginList {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  margin-bottom: 15px;

  li {
    display: flex;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 15px;

    button {
      background-color: $white !important;
      border-radius: $radius !important;
      padding: 12px 10px !important;
      height: auto;
      border: 1px solid $borderColor !important;
      font-size: 14px !important;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      text-transform: capitalize;
      font-size: 14px;

      img {
        margin-left: 10px;

        &.socialIcon {
          margin-right: 10px;
          margin-left: 0px;
        }
      }

      &:hover {
        background-color: $white !important;
        border-color: $primary !important;
      }
    }
  }
  button {
    margin-left: auto;
    height: auto;
    font-size: 14px;
    padding: 6px 16px;
  }
}

// socialToolTip
.socialToolTip {
  .tooltip {
    .tooltip-inner {
      text-align: left !important;
      min-width: 300px !important;

      h2 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      ul {
        margin-bottom: 15px;

        li {
          margin-bottom: 8px;
        }
      }
    }
  }
}

.commentLog {
  width: 200px !important;
  overflow: hidden;
}

.uiDesign {
  border: 1px solid $borderColor;
  border-radius: $radius;
  cursor: pointer;

  h1 {
    border-bottom: 1px solid $borderColor;
    margin-bottom: 15px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    background-color: #f5f5f5;

    ~ label {
      padding: 0 10px 10px 10px;
      width: 100%;
      height: 200px;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

// personal website management
._53Ji7,
._2_g61 {
  padding: 0;
}

.personalWebsiteSetps {
  // margin-top: 40px !important;
  position: relative;

  .nav {
    &.stepProcess {
      position: fixed;
      top: auto;
      bottom: 150px;
      right: 0;
      z-index: 99999999;
      background-color: $white;
      box-shadow: -10px 0 10px 0 rgba($black, 0.06);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      // padding: 20px 0;
      border: none;
      padding: 5px 8px;
      width: 44px;
      height: 178px;
      transition: width 0.5s ease, height 0.5s ease;

      .nav-item {
        width: 100%;

        .nav-link {
          font-weight: 400;
          margin-right: 0 !important;
          position: relative;
          // z-index: 1;
          padding: 10px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          &::before,
          &::after {
            display: none;
            content: "";
            position: absolute;
            top: -60%;
            left: 50%;
            transform: translate(-50%);
            width: 4px;
            height: 100%;
            background-color: #e7eaf3;
            // background-color: red;
            z-index: -1;
          }

          .stepCount {
            width: 28px;
            height: 28px;
            margin: auto;
            border-radius: 50%;
            background-color: #e7eaf3;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 8px;
            color: var($black);
            font-size: 14px;

            &.success {
              background-color: $success !important;
              box-shadow: 0 0 10px 2px rgba($success, 0.6);
            }

            img {
              height: 12px;
            }
          }

          &.active {
            color: $black !important;

            .stepCount {
              background-color: $primary;
              color: $white;
              // z-index: 2;
            }

            &::after {
              animation: movingLine 0.5s ease-in-out 1 both;
              background-color: $success;
            }
          }

          span {
            // display: block;
            display: none;
            text-align: center;
            font-size: 12px;
            width: 28px;
            transition: width 0.5s ease;

            &.stepTitle {
              font-size: 14px;
              font-weight: 600;
            }
          }
        }

        &:first-child {
          .nav-link {
            &::before,
            &::after {
              display: none;
            }
          }
        }
      }

      &:hover {
        width: 113px;
        height: 277px;

        .nav-item {
          .nav-link {
            span {
              display: block;
              width: 97px;
            }
          }
        }
      }
    }
  }

  @keyframes movingLine {
    from {
      height: 0;
    }

    to {
      height: 100%;
    }
  }

  // ._1Lo2h {
  //   margin-bottom: 80px;
  // }

  // ._3uApM {
  //   justify-content: center;

  //   a {
  //     margin-inline: 10px;
  //     text-decoration: none;
  //     height: $height !important;
  //     padding: 0 10px !important;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     max-width: 100px !important;
  //     width: 100%;

  //     &._3CDiP {
  //       background-color: transparent !important;
  //       color: $primary !important;
  //       border-color: $primary;
  //     }

  //     &._hsN1w {
  //       background-color: $primary !important;
  //       color: $white !important;
  //       border-color: $primary;
  //     }
  //   }
  // }

  .tab-pane {
    padding: 0;
  }
}

.publishBottomWrapper {
  position: fixed;
  bottom: 0;
  background-color: $white;
  text-align: center;
  z-index: 99999999;
  width: 100%;
  padding: 10px;
  text-align: center;
  box-shadow: 0 -3px 8px 5px rgba($black, 0.08);

  .btn {
    padding: 15px 30px;
    position: relative;
    left: -100px;
  }
}

.serviceList {
  border: 1px solid $borderColor;

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn {
      font-size: 14px;
      text-transform: capitalize;
    }
  }

  .card-body {
    ul {
      li {
        border: 1px dashed $borderColor;
        padding: 10px;
        border-radius: $radius;
        &:not(:last-child) {
          margin-bottom: 15px;
        }

        .listHeader {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 12px;
        }

        h1 {
          font-size: 16px;
          margin-bottom: 0px;
        }

        p {
          color: $grey;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.searchFiled {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  width: 100%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;

  .btn {
    white-space: nowrap;
    margin-left: 15px;
  }
}

.checkOutWrapper {
  display: flex;

  .chcekoutInfo,
  .payementCardList {
    flex: 1 0 50%;
    padding: 15px;
  }

  .chcekoutInfo {
    ul {
      li {
        display: flex;
        justify-content: space-between;
        color: $black;
        padding: 10px 0;
        border-bottom: 1px dashed $borderColor;

        &:first-child {
          padding-top: 0px;
        }

        div {
          max-width: 250px;
        }
      }
    }
  }

  .payementCardList {
    background-color: #edf4fc80;

    .paymentTitle {
      display: flex;
      justify-content: space-between;

      button {
        font-size: 14px;
        text-transform: capitalize;
      }
    }

    .cardList {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $white;
      padding: 10px;
      border-radius: $radius;
      margin: 12px 0;
      box-shadow: 0 0 10px 0 rgba($black, 0.06);

      label {
        display: flex;
        margin-bottom: 0;

        label {
          font-size: 12px !important;
        }
      }
    }
  }
}

.checkOutModal {
  .modal-content {
    .modal-body {
      padding: 0 !important;
    }
  }
}

.ownDomainInfo {
  h1 {
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.hostingProvider {
  h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
  }

  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      padding-right: 10px;
      padding-top: 10px;

      &:last-child {
        padding-right: 0;
      }

      &:first-child {
        padding-left: 0;
      }

      a {
        display: block;
      }
    }
  }
}

.settingrecordList {
  margin: 10px;

  h1 {
    font-size: 20px;
    margin-bottom: 15px;
  }
}

.recordItems {
  border: 1px dashed $borderColor;
  padding: 15px;
  border-radius: $radius;
  margin-bottom: 20px;

  h1 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  ul {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    li {
      label {
        font-weight: 600;
      }

      .value {
        display: flex;
        align-items: center;
        .nameCode {
          width: 200px;
        }

        span {
          border: 1px solid $borderColor;
          max-width: 250px;
          width: 100%;
          padding: 10px;
          border-radius: $radius;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          // display: -webkit-box;
          // text-overflow: ellipsis;
        }
      }
    }
  }
}
.ps__rail-y,
.ps__rail-x {
  display: none !important;
}

.searchList {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 600px;
  width: 100%;
  position: relative;
  margin-top: 20px;
  margin: auto;

  .searchForm {
    margin-right: 15px;
    position: relative;
    position: absolute;
    top: 110%;
    background-color: $white;
    box-shadow: 0 0 10px 0 rgba($black, 0.1);
    left: 0;
    z-index: 9;

    ul {
      padding: 10px;
      padding-bottom: 0;
      width: 100%;
      width: 500px;
      overflow: auto;
      max-height: 300px;
      height: auto;

      li {
        margin-bottom: 25px;
        cursor: pointer;

        label {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    button {
      width: 100%;
      padding: 10px;
      text-transform: capitalize;
      text-align: center;
    }
  }
}

.manageClientWrapper {
  margin-top: 15px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;

  .manageList {
    // align-items: center;
    border: 1px dashed $borderColor;
    padding: 12px;
    border-radius: $radius;
    flex: 1 0 33.333%;

    position: relative;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    .actionManage {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .clientContent {
      margin-left: 20px;
      flex: 1;
      display: flex;
      align-items: center;
    }

    .clientInfo {
      flex: 1;
      margin-top: 10px;
      text-align: center;
      h2 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      &:last-child {
        text-align: right;
      }
      p {
        margin-bottom: 5px;
      }
    }

    .clientImage {
      border-radius: 50%;
      width: 100px;
      height: 100px;
      display: block;
      margin: auto;

      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.showManyClient {
  // display: flex;
  // align-items: center;
  // justify-content: flex-start;
  margin: 20px 0;

  .custom-control {
    label {
      white-space: nowrap;
    }
  }

  .input-group {
    margin-top: 5px;
    width: 300px;
  }
}

.chooseCredentials {
  h1 {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .custom-control {
    margin-bottom: 15px;
    margin-right: 25px;
  }
}

.chooseSubscriptionPlan {
  display: flex;
  align-items: center;
  justify-content: space-between;

  // flex-wrap: wrap;
  margin: 20px 0 30px;

  .chooseSubContent {
    margin: 0;
    padding-right: 30px;
    width: calc(100% - 200px);

    h2 {
      font-size: 20px;
      color: $secondary;
      margin-bottom: 10px;
      font-weight: 600;
    }

    p {
      color: $grey;
      line-height: 1.8;
      margin-bottom: 0;
    }
  }
}

.switchButton {
  width: 200px;
  height: 50px;
  background-color: $secondary;
  // padding: 10px;
  border-radius: 50px;

  input[type="checkbox"] {
    display: none !important;
  }

  label {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    position: relative;

    &::before {
      width: 100px;
      height: 40px;
      border-radius: 50px;
      background-color: $white;
      content: "";
      position: absolute;
      left: 5px;
      top: 5px;
      z-index: -1;
      transition: 0.2s ease-in-out;
    }

    span {
      width: 100px;
      display: block;
      text-align: center;
      color: $white;
      font-weight: 500;

      &.month {
        color: $tertiary;
      }
    }
  }

  input[type="checkbox"]:checked {
    ~ label {
      &::before {
        left: calc(50% - 5px);
      }

      span {
        &.month {
          color: $white;
        }

        &.year {
          color: $tertiary !important;
        }
      }
    }
  }
}

// agent subscription card
.subscriptionCard {
  height: 100%;
  position: relative;
  overflow: hidden;

  // &::before,
  // &::after {
  //   content: "";
  //   position: absolute;
  //   right: -50px;
  //   top: -30px;
  //   width: 150px;
  //   height: 150px;
  //   border: 20px solid #d1e9fb;
  //   border-radius: 50%;
  //   transform: rotate(45deg);
  //   pointer-events: none;
  // }

  // &::after {
  //   right: -10px;
  //   top: -70px;
  //   transform: rotate(60deg);
  //   height: 180px;
  // }

  .card-body {
    padding: 20px;
    padding-bottom: 80px;
  }

  &.active {
    background-color: #6aebdf3b;
  }

  .subscriptionInfo {
    height: 100px;
    // img {
    //   height: 60px !important;
    //   margin-bottom: 25px;
    // }

    h1 {
      color: $primary;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 10px;
      text-align: center;
      // display: flex;
      // align-items: center;

      // span {
      //   background-color: $primary;
      //   padding: 6px 10px;
      //   color: $white;
      //   font-weight: 400;
      //   border-radius: 50px;
      //   font-size: 10px;
      //   margin-left: 5px;
      // }
    }

    p {
      color: rgba($black, 0.8);
      text-align: center;
      line-height: 1.5;
    }
  }

  .subcriptionPrice {
    margin-top: 30px;
    text-align: center;
    color: rgba($black, 0.8);
    margin-bottom: 20px;
    position: relative;

    .price {
      font-weight: 700;
      font-size: 38px;
      color: $tertiary;

      &.mainPrice {
        font-size: 20px;
        font-weight: 400;
        margin-right: 5px;
        text-decoration: line-through;
      }
    }
    .badge {
      position: absolute;
      top: -10px;
      right: -10px;
      font-size: 12px;
      padding: 5px 10px;
      border-radius: 12px;
      z-index: 1;
    }
  }

  .subscriptionPlanContent {
    h1 {
      font-size: 26px;
      color: $tertiary;
    }

    ul {
      padding-left: 0px;

      li {
        font-size: 14px;
        padding: 10px 0;
        color: rgba($black, 0.9);
        position: relative;
        display: flex;
        align-items: flex-start;

        div {
          line-height: 1.5;
          flex: auto;
          font-weight: 500;

          small {
            font-weight: 400;
            display: block;
          }
        }

        // span {
        //   font-weight: 600;
        //   font-style: italic;
        // }

        img {
          margin-right: 10px;
          position: relative;
          top: 4px;
          height: 14px;
        }
      }
    }
  }

  .extraFeature {
    margin-bottom: 20px;

    h2 {
      font-size: 15px;
      margin-bottom: 8px;
      font-weight: 500;
    }

    p {
      font-size: 14px;
      margin-bottom: 8px;
      font-weight: 400;
    }
  }

  .btnSubscriped {
    position: absolute;
    bottom: 20px;
    width: calc(100% - 100px);
    left: 50px;
    z-index: 9;
  }

  .allfeature {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;

    .feature {
      flex: 1 0 180px;
      // height: 170px;
      flex-grow: 1;
      flex-shrink: 1;
      border: 1px solid $borderColor;
      padding: 10px;
      margin-bottom: 20px;
      margin-right: 18px;
      border-radius: 8px;

      h2 {
        font-size: 14px;
        color: $secondary;
        font-weight: 600;
        margin-bottom: 10px;
        line-height: 1.6;
      }

      ul {
        li {
          font-size: 12px;
          color: $grey;
          padding: 6px 0;

          div {
            font-weight: 400;
          }
        }
      }
    }
  }
}

.showInputBusiness {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .form-group {
    flex: 1;
  }
  .custom-checkbox {
    margin-left: 5px;
  }
}

.starIcons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // width: 150px;
  svg {
    width: 14px;
    height: 14px;
    fill: $warning;
    stroke: $warning;
  }
}

.payCard {
  margin-left: auto;
  max-width: 250px;
  width: 100%;
  li {
    padding: 10px 0;
    &:not(:last-child) {
      border-bottom: 1px dashed $borderColor;
    }
    span {
      &:first-child {
        width: 180px;
        font-weight: 600;
      }
      display: inline-block;
      font-weight: 400;
    }
  }
}

.confirmationWrap {
  text-align: center;
  .checkIcon {
    width: 50px;
    height: 50px;
    background-color: $success;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin-bottom: 15px;
    svg {
      stroke: $white;
      height: 30px;
      width: 30px;
    }
  }
}

.themePreview {
  border: 1px solid $borderColor;
  padding: 10px;
  width: 100%;
  height: 400px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 100%;
  }
}

$z-index-start: 1;
$z-index-step: 1;

@for $i from 1 through 50 {
  .z-index-#{$i} {
    z-index: $z-index-start + ($z-index-step * $i);
  }
}

.star-rating {
  // font-size: 24px;
  display: flex;
  align-items: center;
}

// .star-rating span {
//   display: inline-block;
//   width: 1em;
// }
.star {
  position: relative;
  width: 20px;
  height: 22px;
  font-size: unset;
}
.star-part {
  display: inline-block;
  position: absolute;
}

.star-rating .filled {
  color: gold;
}

.star-rating .partially-filled {
  color: gold;
  overflow: hidden;
  position: relative;
}

.accordianWrap {
  border: 1px solid $borderColor;
  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 8px;
    border-bottom: none !important;
    background-color: $white !important;
  }
  &.open {
    .card-header {
      svg.arrow {
        transform: rotate(180deg);
      }
    }
  }
  .card-body {
    border-top: 1px solid $borderColor;
  }
}

.stateList {
  box-shadow: none;
  border: 1px solid $borderColor;
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: auto;
      display: flex;
      background-color: rgba($primary-rgb, 0.2);
      margin-right: 8px;
      border-radius: 4px;
      margin-bottom: 8px;
      overflow: hidden;
      span {
        padding: 2px 6px;
        display: block;
      }
      .remove {
        cursor: pointer;
        background-color: $form-error;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        svg {
          width: 15px;
          height: 15px;
          stroke: $white;
        }
      }
    }
  }
}
.testimonialsWrap {
  border: 1px solid $borderColor;
  .profileCompanyLogo {
    margin: auto;
  }
}
.questionWrap {
  width: 100%;
  border: 1px solid rgba($black, 0.1);
  border-radius: 10px;
  padding: 15px;
  display: flex;
  align-items: flex-start;
  .questionbox {
    flex: 1;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px dashed $borderColor;
  //   margin-bottom: 15px;
  // }
}

// credit card
.creditCardWrapper {
  // background: linear-gradient(135deg, #000000, #333333);
  border: 1px solid $borderColor;
  padding: 25px 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  @include flexBox(center, flex-start, wrap);
  .icon {
    svg {
      height: 26px;
      fill: $grey;
    }
  }
  .creditNumber {
    margin-left: 10px;
    margin-right: 5px;
    h3 {
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: 500;
      @include flexBox(center, flex-start, nowrap);
      svg {
        circle {
          fill: $black;
        }
      }
      span {
        svg {
          height: 14px;
          width: 14px;
        }
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
    .cardVaild {
      @include flexBox(center, space-between, nowrap);
    }
  }
  .action {
    // display: flex;
    // align-items: center;
    // width: 135px;
    // justify-content: flex-end;
    width: 100%;
    text-align: right;
    margin-top: 20px;
    h6 {
      margin-bottom: 0;
    }
    .btn {
      font-size: 14px;
      text-transform: capitalize;
      &:not(.remove) {
        color: $grey !important;
      }
      + .btn {
        margin-left: 0;
        padding-left: 0;
      }
    }
  }
  &.selected {
    border-color: rgba($black, 0.7);
    .icon {
      svg {
        fill: $black;
      }
    }
  }
}

.credentialsItem {
  border: 1px solid rgba($color: $borderColor, $alpha: 0.7);
  padding: 15px;
  border-radius: 8px;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  .credentialTitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: rgba($color: $black, $alpha: 0.8);
    font-size: 13px;
    span {
      width: 14px;
      height: 14px;
      background-color: $success;
      border-radius: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      svg {
        width: 12px;
        height: 12px;
        stroke: $white;
      }
    }
  }
  ul {
    margin-top: 5px;
    @include flexBox(flex-start, space-between, wrap);
    li {
      flex: 1 0 49%;
      flex-grow: 0;
      @include flexBox(center, space-between, wrap);
      gap: 15px;
      padding: 8px 10px;
      &:only-child {
        flex: 1 1 100%;
        flex-grow: unset;
      }
      &.singleValue {
        flex: 1 0 auto;
      }

      label {
        font-weight: 600;
        margin-bottom: 0;
        font-size: 13px;
      }
      span {
        font-size: 13px;
        font-weight: 400;
        color: rgba($black, 0.8);
      }
      .btn-link {
        font-size: 13px;
        text-transform: capitalize;
        padding: 0;
      }
    }
  }
}

.dropzone {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 1%;
  left: auto;
  right: 1%;
  z-index: 1000;
  width: calc(100% - 250px);
  height: 98%;
  border: 2px dashed #aaa;
  border-radius: 20px;
  padding: 10px;
  text-align: center;
  backdrop-filter: blur(15px);
  background-color: rgba(255, 255, 255, 0.5);
  color: "#bdbdbd";
  transition: "border .24s ease-in-out";
  font-size: 20px;

  // img {
  //   width: 75px;
  //   height: 75px;
  //   margin-bottom: 10px;
  // }
  svg {
    width: 40px;
    height: 40px;
    stroke: $primary;
  }
  h3 {
    margin-top: 20px;
    font-size: 20px;
  }
}

.DragDropFile {
  border: 1px dashed rgba($color: $primary-rgb, $alpha: 0.4);
  padding: 15px;
  background-color: rgba($color: $primary-rgb, $alpha: 0.06);
  border-radius: 8px;
  text-align: center;
  p {
    margin-bottom: 0;
    font-size: 12px;
    margin-top: 10px;
    color: rgba($color: $grey, $alpha: 0.8);
    span {
      color: $primary;
      text-decoration: underline;
    }
  }
  .uploadIcon {
    stroke: $primary;
  }
  .uploadFileProcess {
    svg {
      width: 80px;
    }
    span {
      display: block;
      font-size: 12px;
      margin-top: 10px;
    }
  }
}

.CircularProgressbar-path {
  stroke: $primary;
}
.CircularProgressbar-trail {
  stroke: rgba($black, 0.2);
}
.CircularProgressbar-text {
  fill: $black;
  dominant-baseline: middle; /* Vertical alignment */
  text-anchor: middle; /* Horizontal alignment */
}
.CircularProgressbar-background {
  fill: green;
}

/* Container that holds the entire page content */
.fullPageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ensures full viewport height */
  background-color: #f3f7fe; /* Background color for the full page */
  font-family: "Poppins", sans-serif; /* Font for the content */
}

/* Wrapper for the reset form and logos */
.resetWrapper {
  max-width: 550px;
  width: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

/* Style for the logos */
.projectLogo {
  height: 60px;
  margin: 20px auto;
  display: block;
}

/* Big logo positioned in the background */
.biglogo {
  position: absolute;
  right: -10px;
  bottom: 20px;
  width: 300px;
  opacity: 0.7;
  z-index: -1;
}

/* Button styling */
.btnSubmit {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  font-size: 16px;
  margin: 10px 0;
  font-weight: 600;
  background-color: #4c96ce;
  border: 1px solid #4c96ce;
  color: white;
  cursor: pointer;
}

.loginFooter {
  color: #828282;
  text-align: center;
  margin: 10px auto;
  font-size: 13px;
  font-weight: 400;
}

/* Link style for 'Back to App' */
a.backLink {
  color: #4c96ce;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: none;
}

.buttonContainer {
  margin: 15px 0; /* Adds a gap between the buttons */
}
.standardFeeWrap .radioWrapper {
  margin-top: 10px; /* Adjust as needed */
}

.authButton {
  width: 100%; /* Makes the button full width */
  height: 56px; /* Increases the button height */
  font-size: 18px; /* Increases the font size */
  border-radius: 8px; /* Rounds the button corners */
}

.svg-container {
  display: flex; /* Use flexbox to center the image */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 20vh; /* Full viewport height */
}

.svg-image {
  width: 80px; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
}

// personal website page builder design

.agentPageBuilder {
  .themeColorSetup {
    position: fixed;
    top: 0;
    left: auto;
    transition: 0.5s ease-in-out;
    right: -400px;
    width: 400px;
    min-height: 100vh;
    background-color: $white;
    z-index: 999;
    box-shadow: 0 0 10px 5px rgba($black, 0.08);
    padding: 20px;
    .settingIcon {
      position: absolute;
      transform: translateY(-20%);
      top: 180px;
      left: -45px;
      width: 45px;
      height: 45px;
      background-color: white;
      border: 1px solid $borderColor;
      border-right: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
        animation: spin 3s linear infinite;
      }
    }
    .themeColor {
      h1 {
        text-align: center;
        color: $black;
        margin-bottom: 25px;
      }
    }
    &.show {
      right: 0;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  footer {
    background-color: $black;
    padding: 80px 25px 0;

    .footerAbout {
      // margin-top: 10px;
      label {
        margin-bottom: 0;
      }
      img {
        width: auto;
        height: 60px;
        margin-bottom: 25px;
      }
      p {
        color: $white;
        font-size: 16px;
      }
    }

    .socialMedia {
      display: flex;
      align-items: center;
      li {
        width: 25px;
        height: 25px;
        border-radius: 6px;
        margin-right: 10px;
        // background-color: red;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        svg {
          width: 18px;
          height: 18px;
          stroke: $white;
          fill: transparent;
        }
      }
    }

    .footerLink {
      h3 {
        font-size: 20px;
        color: $white;
        margin-bottom: 30px;
        font-weight: 500;
      }
      .menuLink {
        li {
          display: block;
          font-size: 16px;
          padding-bottom: 12px;
          font-weight: 500;
          color: rgba($color: $white, $alpha: 0.6);
          &:hover,
          &:focus {
            color: $white;
          }

          &:last-child {
            padding-bottom: 0;
          }
        }
      }
      .contactInfo {
        li {
          svg {
            margin-right: 8px;
            path,
            rect {
              stroke: $white !important;
              fill: transparent;
            }
            width: 18px;
            height: 18px;
          }
          span {
            flex: 1;
          }

          color: rgba($color: $white, $alpha: 0.6);
          padding-bottom: 10px;
          display: block;
          font-size: 16px;
          &:hover,
          &:focus {
            color: $white;
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
    .copyright {
      @include flexBox(center, space-between, wrap);
      padding: 20px 0px;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: $grey;

      margin-top: 15px;
      .otherLinks {
        @include flexBox(center, flex-start, wrap);
        span {
          color: $grey;
          &:last-child {
            padding-left: 10px;
          }
        }
      }
    }
  }
  [contenteditable] {
    border: 2px solid transparent; /* Default border color */
    border-radius: 4px;
    outline: none; /* Remove default focus outline */
    transition: all 0.3s;
    position: relative;
    border: 1px dashed transparent;

    &:hover {
      background: rgba($white, 0.15);
      border-color: $borderColor !important;
      box-shadow: 0 0 10px rgba(136, 136, 136, 0.3);
      transition: all 0.3s;

      &::after {
        content: "\f040";
        font-family: "FontAwesome";
        position: absolute;
        font-size: 10px;
        color: $white;
        background-color: $primaryWebsite;
        bottom: 0;
        right: 0;
        width: 16px;
        height: 16px;
        line-height: 16px;
        border-top-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding: 0;
        text-align: center;
      }
    }
  }

  [contenteditable]:focus {
    border-color: $black; /* Change border color on focus */
  }
  // padding: 20px 0px;
  .btn {
    font-size: 16px;
    text-transform: capitalize;
    border-radius: 0 0 0 20px;
  }
  a {
    &.btn-light {
      display: inline-block;
      background-color: $white;
      border-color: $white;
      color: $primaryWebsite !important;
      border-radius: 4px;
      &.outline {
        background-color: transparent;
        border: 1px solid $white;
        color: $white !important;
        &:hover {
          color: $primaryWebsite !important;
          background-color: $white;
        }
      }
    }
    + a {
      margin-left: 10px;
    }
  }

  .tab-content {
    .tab-pane {
      padding: 0;
    }
  }
  p {
    font-size: 16px;
    color: rgba($black, 0.7);
    font-weight: 400;
    line-height: 1.8;
  }

  .btn-outline-primary {
    border-color: $primaryWebsite !important;
    color: $primaryWebsite !important;

    &:hover {
      color: rgba($white, 0.8) !important;
    }
  }

  .btn-outline {
    background-color: $white;
    border: 1px solid $primaryWebsite;
    color: $primaryWebsite;

    &:hover {
      background-color: $primaryWebsite !important;
      color: $white;
      border-color: $primaryWebsite !important;
    }

    &:focus {
      background-color: $primaryWebsite !important;
      border-color: $primaryWebsite !important;
    }
  }

  .phoneBtn {
    &::before {
      content: "\f095";
      font-family: "FontAwesome";
      font-size: 14px;
      color: $primaryWebsite;
      padding: 0;
      text-align: center;
      margin-right: 4px;
    }

    &:hover,
    &:focus {
      &::before {
        color: $white;
      }
    }
  }
}
.flotingButton {
  position: fixed;
  top: auto;
  left: auto;
  right: 20px;
  bottom: 80px;
  z-index: 99999;
  border-radius: 10px !important;
}
.deleteButton {
  position: absolute;
  top: -10px;
  right: -5px;
  width: 30px;
  height: 30px;
  border-radius: 50px !important;
  padding: 0 !important;
  z-index: 9999;
  svg {
    width: 20px !important;
    height: 20px !important;
    margin-left: 0 !important;
  }
}

// top header
.headerTop {
  @include flexBox(center, space-between, nowrap);
  padding: 0px 0px 15px;
  .headerTopRight {
    .btn {
      &:last-child {
        margin-left: 15px;
      }
      &.navbarToggle {
        width: 30px;
        display: none;
        height: 30px;
        position: relative;
        padding: 0 !important;
        span {
          display: block;
          width: 60%;
          height: 2px;
          margin-left: auto;
          background-color: #05070f;
          &:after,
          &:before {
            content: "";
            position: absolute;
            top: 5px;
            left: 0;
            height: 2px;
            width: 100%;
            background-color: #05070f;
            transition: all 0.5s ease-in-out;
          }
          &:after {
            top: auto;
            bottom: 5px;
          }
        }
        &.open {
          span {
            background-color: transparent;
            &:after {
              bottom: 10px;
              transform: rotate(-45deg);
            }
            &:before {
              top: 15px;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
}

// .mainLogo {
//   img {
//     height: 40px;
//     width: auto;
//   }
// }

.personalLogo {
  width: auto;
  cursor: pointer;
  height: 50px;
  img {
    width: auto;
    height: 100%;
  }
}

// header bottom
.headerBottom {
  background-color: $primaryWebsite;
  padding: 10px 0;
  @include flexBox(center, center, nowrap);
  .nav-item {
    a {
      cursor: pointer;
      padding: 20px 15px;
      color: rgba($white, 0.6);
      font-weight: 400;
      font-size: 18px;
      position: relative;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        height: 1px;
        background-color: transparent;
        transition: 0.3s;
        transition-timing-function: cubic-bezier(0.58, 0.3, 0.005, 1);
      }
      &.active,
      &:hover {
        color: $white;
        &::after {
          background-color: $white;
        }
      }
    }
  }
}

.getStartWrapper {
  background-color: $secondaryWebsite;
  padding: 100px 60px;
  text-align: center;
  h2 {
    color: $secondaryTextWebsite;
    font-size: 40px;
    font-weight: 600;
    &:first-child {
      font-weight: 300;
      margin-bottom: 15px;
    }
  }
  .getStartAction {
    margin-top: 30px;
    .btn {
      padding: 15px 35px !important;
      &:last-child {
        margin-left: 15px;
      }

      &.btn-white {
        color: $primaryWebsite;

        &:hover {
          color: $primaryWebsite !important;
        }

        &:focus {
          color: $primaryWebsite !important;
        }
      }

      &.outline {
        color: $white;

        &:hover {
          color: $primaryWebsite !important;
        }
      }
    }
  }
}

.sectionIntro {
  .sectionTitle {
    font-size: 40px;
    font-weight: 600;
    color: $black;
    margin-bottom: 10px;
    text-align: unset;
  }
  &.withDark {
    .sectionTitle {
      color: $secondaryTextWebsite;
      .expertise {
        color: rgba($color: $white, $alpha: 0.6);
        margin-left: 10px;
      }
    }
    p {
      color: $secondaryTextWebsite;
    }
  }
}

.sectionTitle {
  font-size: 40px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 40px;
}

.introSection {
  // display: flex;
  // align-items: center;
  // flex-wrap: wrap;
  .sectionIntro {
    text-align: center;
    margin-bottom: 40px;
  }
}

// hero section
.heroSection {
  width: 100%;
  padding: 40px 60px;
  height: calc(100vh - 35vh);
  border-bottom-left-radius: 200px;
  position: relative;
  z-index: 1;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: $black, $alpha: 0.7);
    border-bottom-left-radius: 200px;
    z-index: -1;
  }

  .heroContent {
    h1 {
      font-size: 60px;
      text-align: center;
      color: $white;
      font-weight: 600;
      margin-bottom: 30px;
    }

    p {
      color: rgba($color: $white, $alpha: 0.7);
      line-height: 1.8;
      text-align: center;
    }

    .btnAction {
      text-align: center;
      margin-top: 40px;
      .btn-outline-primary {
        background: $white !important;
        &:hover {
          background-color: $primary !important;

          &:focus {
            color: rgba($white, 0.8) !important;
          }
        }

        &:focus {
          color: $white;
        }
      }
      .btn {
        margin-left: 20px;
        background-color: $primaryWebsite;

        &:hover,
        &:focus {
          border-color: $primaryWebsite !important;
          background-color: $primaryWebsite !important;
        }
      }

      .phoneBtn {
        &:focus {
          color: $white !important;
        }

        &:hover {
          border-color: $primaryWebsite !important;

          &:focus {
            &::before {
              color: $white !important;
            }
          }
        }
      }
    }
  }
}

.backgroundAction {
  position: absolute;
  top: auto;
  top: 15px;
  left: 15px;
  .btn {
    border-radius: 50px !important;
    padding: 0;
    width: 45px;
    height: 45px;
    margin-right: 15px;
    svg {
      margin: 0;
      width: 22px;
      height: 22px;
    }

    &.btn-secondary {
      background-color: $primaryWebsite;
      border-color: $primaryWebsite;

      &:hover {
        background-color: $primaryWebsite !important;
        border-color: $primaryWebsite;
      }
    }
  }
}

// notary services
.notaryWrapper {
  padding: 50px 0 50px;
  .notaryServiceCard {
    padding-bottom: 50px;

    p {
      text-align: center;
    }
  }
}

.serviceCard {
  box-shadow: 0px 0px 10px 0px rgba($black, 0.2);
  border: none !important;
  min-height: 270px !important;
  border-radius: 0 0 0 50px !important;
  .card-body {
    padding-bottom: 40px;
    img {
      width: 70px;
      height: 70px;
      margin: auto;
      display: block;
    }
    h1 {
      font-weight: 600;
      font-size: 20px;
      display: inline-block;
      margin-top: 20px;
      margin-bottom: 0;
    }
    p {
      // @include lineClamp(3);
      color: rgba($black, 0.7);
    }

    button {
      height: auto;
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 0;
      padding: 6px 28px;
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.serviceGrid {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.serviceGrid .serviceCard.card {
  flex: 1 0 31.33%;
  flex-grow: 0;
  flex-shrink: 1;
  margin: 0;
}

.sliderSection {
  position: relative;
  padding: 50px 0px 40px;
  cursor: pointer;
  &::after {
    width: 100%;
    height: 100%;
    content: "Edit Sevice List";
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    text-decoration: underline;
    font-weight: 400;
    color: $white;
    background-color: rgba($black, 0.7);
    // display: none;
  }
}

.serviceSlider {
  .slick-list {
    margin: 0 -15px; /* Offset the padding for alignment */
  }
  .slick-slide {
    padding: 10px 15px; /* Add horizontal spacing (gutter) */
    box-sizing: border-box; /* Ensure padding does not affect width */
    > div {
      width: 100%;
    }
  }
}
// .serviceSlider {
ul.slick-dots {
  bottom: -40px;
  li {
    width: auto;
    height: auto;
    button {
      width: 20px;
      height: 6px !important;
      padding: 0 !important;
      background: $primary;
      border-radius: 30px !important;
      transition: 0.5s ease-in-out;
      opacity: 0.5;
      &::before {
        display: none;
      }
    }
    &.slick-active {
      button {
        width: 40px;
        opacity: 1;
      }
    }
  }
}
// }

// contact us page
.innerTitleSection {
  // background-image: url("http://www.carolinaattorneynetwork.com/wp-content/uploads/2023/01/customer-support-hotline-people-connect-phone-application-contact-us-man-cellphone.webp");
  background-position: top left center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 180px 60px 180px 61px;
  border-bottom-left-radius: 150px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: $black, $alpha: 0.7);
    z-index: -1;
  }
  h1 {
    text-align: center;
    color: $white;
    font-size: 40px;
  }
}

.contactSection {
  padding: 80px 0;
  h2 {
    font-size: 30px;
  }

  .contactForm {
    z-index: 1;
    width: 100%;
    h2 {
      margin-bottom: 30px;
      font-size: 30px;
      font-weight: 600;
    }
    form {
      border-radius: 14px;
      background-color: $white;
      padding: 30px;
      box-shadow: 0px 0px 46px 0px rgba(0, 0, 0, 0.10196078431372549);
      label {
        font-weight: 600;
        color: $black;
      }
      .form-control {
        border: none;
        background-color: #f5f5f5;
        font-weight: 400;
      }
    }
  }

  .contactCard {
    margin-top: 20px;
    h3 {
      font-size: 18px;
      margin-bottom: 10px;
      font-weight: 500;
    }
    p {
      margin-bottom: 0;
    }
    div {
      display: block;
      margin-bottom: 15px;
      color: $black;
      svg {
        width: 18px;
        height: 18px;
        margin-right: 10px;
        margin-left: 0 !important;
        &.lucide-mail {
          stroke: $black;
        }
        fill: transparent;
      }
      &:hover {
        color: $primaryWebsite !important;
      }
    }
  }
  .contactLinks {
    h2 {
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 40px;
      margin-bottom: 30px;
    }
  }
}

.ourProcess {
  background-color: $secondaryWebsite;
  padding: 100px 0;
  .row {
    align-items: center;
  }
}

.verticalSteps {
  position: relative;
  max-width: 650px;
  margin: auto !important;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 8px;
    height: 100%;
    border-radius: 5px;
    background-color: rgba($color: $borderColor, $alpha: 0.6);
  }
  li {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 30px 0;
    position: relative;
    .stepContent {
      max-width: 50%;
      padding: 0px 20px;
      width: 100%;
      h3 {
        color: $secondaryTextWebsite;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 15px;
      }
      p {
        color: $secondaryTextWebsite;
        font-size: 14px;
        margin-bottom: 0;
      }
    }

    .stepIndicator {
      position: relative;
      max-width: 50%;
      width: 100%;

      .indicateIcon {
        @include flexBox(center, center, wrap);
        width: 45px;
        height: 45px;
        background-color: $white;
        border: 1px solid $secondaryWebsite;
        border-radius: 50px;
        margin: 0px auto 0;
        z-index: 2;
        position: relative;
        svg {
          width: 25px;
          height: 25px;
        }
      }
    }
    &::before {
      content: "";
      position: absolute;
      top: 25%;
      left: 50%;
      transform: translateX(-50%);
      width: 20px;
      height: 20px;
      border-radius: 50px;
      background-color: $white;
      border: 3px solid $secondaryWebsite;
      z-index: 9;
      // box-shadow: 0 0 2px 2px rgba($color: $black, $alpha: 0.2);
    }
    &::after {
      content: "";
      position: absolute;
      top: 50px;
      left: 50%;
      width: 146px;
      height: 2px;
      background-color: rgba($color: $borderColor, $alpha: 0.6);
      z-index: 1;
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
      &::after {
        left: auto;
        right: 50%;
      }
    }
  }
}

.fedbackSection {
  background-color: rgba($secondaryText-rgb, 0.8);
  padding: 80px 0;

  // .slider-container {
  //   padding-top: 20px;
  //   .slick-slider {
  //     overflow: hidden;
  //   }
  // }

  .slick-list {
    // overflow: visible;
    padding-top: 15px;
  }

  .btn-secondary {
    background-color: $primaryWebsite;

    &:hover,
    &:focus {
      background-color: $primaryWebsite !important;
    }
  }
}

.slider-container {
  .slick-arrow {
    display: none !important;
  }
  .slick-slide > div {
    margin-right: 10px;
  }
  .sliderItem {
    border: 1px solid rgba($color: $borderColor, $alpha: 0.6);
    padding: 20px;
    border-radius: 10px;
    background-color: $white;
    position: relative;
    svg {
      width: 40px;
      height: 40px;
    }

    .clientInfo {
      display: flex;
      align-items: center;
      margin-top: 20px;
      img {
        border: 1px solid $borderColor;
        border-radius: 50px;
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
      .info {
        margin-left: 10px;
        h4 {
          font-size: 16px;
          margin: 0;
          font-weight: 600;
        }
        span {
          color: $grey;
        }
      }
    }
  }
  &.singleCard {
    .sliderItem {
      max-width: 400px;
    }
  }
}

.TimezoneWrap {
  position: relative;
  width: 100%;
  label {
    display: block;
  }
  .btn-link {
    font-size: 14px !important;
    background-color: transparent !important;
    padding: 4px 10px 4px 7px !important;
    border-radius: 20px;
    color: $black !important;
    &:hover {
      background-color: rgba($black, 0.1) !important;
    }
    svg {
      width: 18px;
      stroke: $black;
    }
  }
}

// dropdown wrapper
.dropdown-wrapper {
  position: relative;
  margin-bottom: 20px;
  text-align: right;
  .toggleButton {
    margin-left: auto;

    &:hover {
      background-color: $primaryWebsite !important;
    }

    &:focus {
      border-color: $primaryWebsite;
    }
  }
}

.dropdown-container {
  width: 400px;
  height: 400px;
  border: 1px solid #ccc;
  overflow: hidden;
  position: relative;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 99;
  background-color: $white;
  // padding: 10px;
  border-radius: 8px;
  text-align: center;
}
.search-container {
  border-bottom: 1px dashed $borderColor;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    height: auto;
  }
  .btn {
    height: auto;
    font-size: 14px;
    padding: 10px 20px;
    white-space: nowrap;
    border-radius: 6px !important;
    margin-left: 10px;
    background-color: $primaryWebsite;

    &:hover,
    &:focus {
      background-color: $primaryWebsite !important;
    }
  }
}

.dropdown-header {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  // padding: 10px;
  // border-bottom: 1px dashed #ccc;
  // background-color: #f9f9f9;
  padding: 10px 0px 0;
  text-align: left;
  button {
    font-size: 14px !important;
    svg {
      width: 20px;
      height: 20px;
      margin-left: 0 !important;
    }
  }
}

.dropdown-content {
  height: calc(100% - 50px);
  overflow-y: auto;
}

.no-results {
  font-weight: 600;
  padding: 10px 0;
}

// .back-button,
// .close-button {
//   background: none;
//   border: none;
//   cursor: pointer;
//   font-size: 16px;
// }

// .back-button:hover,
// .close-button:hover {
//   color: #007bff;
// }

.state-item,
.city-item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: red;
  padding: 10px;
  border-bottom: 1px dashed rgba($borderColor, 0.8);
  .custom-checkbox {
    margin-top: -15px;
  }
  label {
    text-align: left;
    display: block;
    font-size: 14px;
    flex: 1;
    // background-color: orange;
    margin-bottom: 0;
    font-weight: 500;
    cursor: pointer;
    // margin-left: 5px;
  }
  .btn {
    &.btn-link {
      color: $primaryWebsite !important;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
  &:hover {
    background-color: rgba($color: $primary-rgb, $alpha: 0.08);
  }

  .custom-checkbox {
    .custom-control-label::before {
      border-color: $primaryWebsite;
    }

    .custom-control-input:checked ~ .custom-control-label::after {
      background-color: $primaryWebsite;
    }

    .custom-control-label::before,
    .custom-control-label::after {
      top: -2px;
    }
  }
}

// .state-item input,
// .city-item input {
//   margin-right: 10px;
// }

// .state-item button,
// .city-item button {
//   background: none;
//   border: none;
//   cursor: pointer;
//   font-size: 16px;
// }

// faq section
.faqSection {
  padding: 80px 0;
}
.faqAccordion {
  width: calc(100% - 200px);
  margin: auto;
  margin-top: 40px;
  .card {
    box-shadow: none;
    margin-bottom: 20px !important;
    border: 1px solid #dfdfdf !important;
    border-radius: 4px;
    padding: 15px;
    border-bottom-left-radius: 30px;
    overflow: hidden;
    .card-header {
      cursor: pointer;
      border: none;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: transparent !important;
      padding: 0;
      font-weight: 500;
      span {
        color: $black;
        font-size: 16px;
        flex: 1;
      }
      .arrowIcon {
        @include flexBox(center, center, wrap);
        width: 25px;
        height: 25px;
        border: 1px solid $primaryWebsite;
        border-radius: 50px;
        svg {
          width: 18px;
          height: 18px;
          stroke: $primaryWebsite;
          transition: 0.2s ease-in-out;
        }
      }
      &.openCard {
        .arrowIcon {
          svg {
            transform: rotate(180deg);
          }
        }
      }

      .btn-danger {
        width: 25px;
        height: 25px;
        border-radius: 50px;
        padding: 0;
        line-height: 23px;
        margin-inline: 5px;

        svg {
          margin-left: 0;
          width: 15px;
          height: 15px;
          vertical-align: -2px;
        }
      }
    }
    .accordion-collapse {
      border-top: 1px solid rgba($black, 0.2);
      margin-top: 15px;
    }
    .card-body {
      padding: 0;
      padding-top: 15px;
      color: rgba($black, 0.6);
    }
  }
  .btn-secondary {
    margin: auto;
    width: 300px;
    background-color: $primaryWebsite;

    &:hover,
    &:focus {
      background-color: $primaryWebsite !important;
    }
  }
}

// client log in
.clientLoginWrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 200px 0 200px;
  position: relative;
  background: url("../assets/img/bg1.jpg") no-repeat center;
  position: relative;
  z-index: 1;
  &::after {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.7);
    z-index: -1;
  }

  .formWrapper {
    background-color: $white !important;
    padding: 50px 30px;
    border-radius: $radius;

    .formInput {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .btn {
        margin-left: 15px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .btn {
      white-space: nowrap;
      background-color: $primaryWebsite;

      &:hover,
      &:focus {
        background-color: $primaryWebsite !important;
      }
    }
  }
}

.clientLoginContent {
  h1 {
    color: $white;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  p {
    color: $white;
    line-height: 2;
  }
}

// services area
.servicesArea {
  padding: 50px 0;
}
.stateCardList {
  // display: flex;
  // align-items: center;
  // gap: 15px;
  // z-index: 1;
  position: relative;
  // margin-top: 40px;
  .sliderCard {
    padding: 10px 0px 0;
    height: 100%;
  }
  .card {
    cursor: pointer;
    // overflow: hidden;
    margin: 0px 10px;
    height: 100%;
    .card-body {
      padding: 20px 10px;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      @include lineClamp(2);
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      svg.mapIcon {
        position: absolute;
        bottom: -30px;
        right: 50%;
        transform: translateX(50%);
        opacity: 0.1;
        width: 100px;
        height: 100px;
        pointer-events: none;
        stroke: $primary;
      }
      .removeIcon {
        position: absolute;
        right: -10px;
        top: -10px;
        padding: 0;
        width: 30px;
        height: 30px;
        border-radius: 50px;
        svg {
          margin-left: 0;
        }
      }
    }
    &.active {
      border: 1px solid $primary;
    }
  }
  .slick-track {
    display: flex;
  }
  .slick-list {
    .slick-slide {
      height: auto;
      > div {
        width: 100%;
        height: 100%;
      }
    }
  }
  button.slick-arrow {
    background-color: $primary;
    border: none;
    box-shadow: none;
    border-radius: 50px;
    width: 26px;
    height: 26px;
    &::before,
    &::after {
      display: none;
    }
    svg {
      width: 18px;
      height: 18px;
      stroke: $white;
    }
  }
}
.addMore {
  .btn {
    width: 100%;
    background-color: $primaryWebsite;

    &:hover,
    &:focus {
      background-color: $primaryWebsite !important;
    }
  }
}
.stateInnerData {
  background-color: rgba($color: $primary-rgb, $alpha: 0.05);
  padding: 20px;
  border-radius: 10px;
  // margin-top: 20px;
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    li {
      background-color: rgba($color: $primary-rgb, $alpha: 0.08);
      padding: 4px 5px 4px 10px;
      border: 1px solid rgba($color: $primary-rgb, $alpha: 0.2);
      border-radius: 50px;
      font-size: 12px;
      display: flex;
      align-items: center;

      .removeIcon {
        background-color: $form-error;
        width: 16px;
        height: 16px;
        border-radius: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        svg {
          width: 12px;
          height: 12px;
          stroke: $white;
        }
      }
    }
  }
}

.closingProvider {
  text-align: center;
  background-color: #f5f5f5;
  padding: 80px 0;

  p {
    font-size: 16px;
    color: rgba($color: $black, $alpha: 0.5);
    line-height: 1.8;
    padding: 0 50px;
  }
}

.serviceWrapper {
  padding: 60px 0;
  .row {
    padding-bottom: 80px;
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
    .serviceContent {
      h2 {
        margin-bottom: 20px;
        font-size: 35px;
        font-weight: 600;
      }
      p {
        margin-bottom: 30px;
      }
    }

    .serviceImage {
      width: 95%;
      overflow: hidden;
      margin-left: auto;
      border-bottom-left-radius: 100px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &:nth-child(2n) {
      .serviceImage {
        margin-left: 0;
        // border-bottom-left-radius: 0;
        // border-bottom-right-radius: 100px;
      }
    }
  }
}

// about use page
.aboutSection {
  padding: 80px 0;
  h1 {
    color: $black;
    font-size: 40px;
    font-weight: 600;
    line-height: 1.5;
    margin: 0 0 15px;
  }
  h2 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  img {
    border-radius: 10px;
    margin: auto;
    overflow: hidden;
  }
}

//
.whoWeSection {
  padding: 80px 0 10px;
  // background-color: #f5f5f5;
  .row {
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
    h2 {
      font-weight: 600;
      font-size: 40px;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 30px;
      font-weight: 500;
    }
  }
  img {
    width: 90%;
    height: 100%;
    margin: auto;
    border-radius: 15px;
  }

  & + .container {
    .btn {
      background-color: $primaryWebsite;

      &:hover,
      &:focus {
        background-color: $primaryWebsite !important;
      }
    }
  }
}

.ourMissonSection {
  padding: 70px 0 70px;
  h2 {
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 20px;
  }
  img {
    width: 95%;
    height: auto;
    margin-left: auto;
    border-radius: 10px;
  }
}

// media queries
@media screen and (max-width: 1199px) {
  .clientLoginWrapper {
    padding: 100px 0;
  }
  .innerTitleSection {
    padding: 100px 0;
    border-bottom-left-radius: 100px;
    &::before {
      border-bottom-left-radius: 100px;
    }
  }

  .serviceGrid {
    .serviceCard {
      &.card {
        flex: 1 0 48%;
        flex-grow: 0;
        flex-shrink: 1;
        margin: 0;
      }
    }
  }

  .clientLoginContent {
    text-align: center;
  }

  .heroSection {
    .heroContent {
      h1 {
        font-size: 40px;
      }
    }
  }
  .automationList {
    li {
      .automationItem {
        flex: 0 0 30%;
      }
    }
  }

  .subscriptionCard {
    .card-body {
      padding: 15px;
      padding-bottom: 60px;
    }

    .btnSubscriped {
      width: calc(100% - 40px);
      left: 20px;
    }

    .allfeature {
      .feature {
        margin-right: 0;
        flex: auto;
      }
    }
  }
  .socialLoginList {
    button {
      margin-left: 0;
    }
  }

  // .documentList {
  //   li {
  //     flex-wrap: wrap;
  //   }
  // }
}

@media screen and (min-width: 992px) {
  .main-panel {
    width: calc(100% - 220px);
  }

  .hideDesktop {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .mobileSearchFiled,
  .downloadButtonPos {
    display: none;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199.98px) {
  .loginWrapper {
    .loginRight {
      flex: 0 0 45%;
    }

    .loginLeft {
      .loginDashboard {
        height: 55vh;
        width: 90%;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .loginWrapper {
    .loginLeft {
      .loginDashboard {
        height: 50vh;
        width: 95%;
      }
    }
  }
}

// @media screen and (max-width: 1299px) {
//   .creditCardWrapper {
//     .action {
//       display: block;
//       width: 100%;
//       margin-top: 15px;
//       text-align: center !important;
//     }
//   }
// }

@media screen and (max-width: 991.98px) {
  .clientLoginWrapper {
    .formWrapper {
      .formInput {
        flex-wrap: wrap;
        .flex-fill {
          flex: 1 1 100% !important;
        }
        .OtpWrap {
          margin: 20px auto;
          .btn {
            width: 150px;
            margin-left: 0 !important;
          }
        }
      }
    }
  }

  .publishBottomWrapper {
    .btn {
      left: 0;
    }
  }

  .contactSection {
    .contactLinks {
      h2 {
        font-size: 30px;
      }
    }
  }

  .aboutSection h2 {
    font-size: 22px;
  }
  .agentPageBuilder {
    .container {
      width: 100%;
      max-width: 100%;
    }
  }

  .sectionIntro.text-right {
    text-align: center !important;
  }

  .ourProcess {
    .sectionIntro {
      margin-top: 30px;
    }
  }

  .aboutSection h1,
  .sectionIntro .sectionTitle,
  .sectionTitle,
  .serviceWrapper .row .serviceContent h2,
  .whoWeSection .row h2 {
    font-size: 27px;
  }

  .headerTop {
    // box-shadow: 0 10px 10px 0 rgba($black, 0.5);
    // margin-bottom: 15px;
    .headerTopRight {
      .btn {
        display: none;
        &.navbarToggle {
          display: block;
        }
      }
    }
  }

  .heroSection {
    .heroContent {
      h1 {
        font-size: 45px;
      }
    }
  }

  .sectionIntro {
    .sectionTitle {
      font-size: 27px;
    }
  }

  .headerBottom {
    position: fixed;
    top: 0;
    // right: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    transition: 0.5s ease-in-out;
    background-color: $primary;
    z-index: 999999999;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
    li {
      width: 100%;
      a {
        width: 100%;
        padding: 14px 25px !important;
        font-size: 16px !important;
        transition: 0.5s ease-in-out;
        font-weight: 600;
        border-bottom: 1px dashed rgba($color: $primary-rgb, $alpha: 0.5);
        &::after {
          width: 8px !important;
          height: 8px !important;
          border-radius: 10px;
          top: 50%;
          transform: translateY(-50%);
          left: 10px !important;
          border: 1px solid $white;
          display: none;
        }
        &.active {
          &::after {
            display: block;
          }
        }
      }
    }
    &.open {
      left: 0;
    }
  }

  .faqAccordion {
    width: 100%;
  }

  .heroSection {
    padding: 0px;
  }

  .dropzone {
    width: 98%;
  }
  .showInputBusiness {
    flex-direction: column;
    align-items: flex-start;
    .form-group {
      flex: 1;
    }
    .custom-checkbox {
      margin-left: 0px;
    }
  }
  .chooseSubscriptionPlan {
    flex-direction: column;

    .chooseSubContent {
      text-align: center;
      margin-right: 0;
      margin-bottom: 20px;
      width: 100%;
    }
  }

  .referralLink {
    justify-content: center;

    a {
      max-width: unset;
    }
  }

  .responsiveTableCard {
    .cardInfo {
      li {
        .dataWrap {
          .btnAction {
            button {
              height: auto !important;
              padding: 8px 12px !important;
            }
          }

          .viewReceipt {
            font-size: 12px;
          }
        }
      }
    }
  }

  .credentialImage {
    width: 100%;
  }

  .assignWrapper {
    .automationAction {
      text-align: center;
    }

    .assignWrap {
      justify-content: center;
    }

    .automationAction {
      margin: 10px 0;
    }
  }

  .alreadyAssinged {
    ul {
      margin: 0;
      overflow: visible;
      flex-wrap: wrap;

      li {
        margin-right: 0px !important;
        margin-bottom: 15px;
      }
    }
  }

  //
  .assingedAgent {
    flex-direction: column;
    margin: 5px 0 15px 0;

    .customSelect {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 15px;
    }
  }

  .aboutAgent {
    flex-direction: column;

    .uploadProfile {
      margin-right: 0 !important;
    }

    .agentInfo {
      text-align: center;
      margin-top: 20px;

      h3 {
        justify-content: center;
      }
    }
  }

  .automationList {
    li {
      .automationItem {
        flex: 0 0 46%;
      }
    }
  }

  .filterContainer {
    &.responsiveView {
      .filterIcon {
        display: none;
      }

      .filterWrapper {
        .form-group {
          width: 50% !important;

          .dateRange {
            width: auto !important;
            max-width: inherit !important;
          }
        }
      }
    }
  }

  .pdfContent,
  .pdfView {
    position: unset;
    margin-top: 20px;
    height: auto;
    min-height: 400px !important;
    iframe {
      height: 100vh;
    }
  }

  .hideMobile {
    display: none;
  }

  .saveCardList {
    .CardDetail {
      width: 100%;
    }
  }

  // sign up
  .registrationItem {
    label {
      width: 140px;
      height: 120px;
    }
  }

  // kyc
  .completeKyc {
    padding: 15px;

    p {
      width: 100%;
    }
  }

  .responsiveTitle {
    display: flex;

    .filterBarIcon {
      display: none;
    }
  }

  .headerPageInfo,
  .navigationButton {
    display: none !important;
  }

  // nvbar
  .nav-open {
    .main-panel {
      -webkit-transform: translate3d(220px, 0, 0);
      transform: translate3d(220px, 0, 0);
    }
  }

  .main-panel {
    .navbar {
      padding: 5px 15px;
      background-color: $secondary;
      border-bottom: none;
      border-bottom-left-radius: 25px;

      .navbar-brand {
        display: block;
        max-width: 180px;
      }

      .navbar-collapse {
        position: absolute;
        top: 102%;
        background: white;
        width: 100px;
        left: auto;
        right: 0;
      }
      .btn-magnify {
        padding-left: 0;
      }

      .navbar-toggler {
        width: auto !important;
        padding: 0 !important;
        overflow: hidden;

        .navbar-toggler-bar {
          background-color: $primary;
        }

        &.sideBarIcon {
          .navbar-toggler-bar {
            height: 2px;
            border-radius: 10px;
            width: 22px;

            &:not(:first-child) {
              margin-top: 4px;
            }

            &.bar1 {
              width: 10px;
            }

            &.bar3 {
              width: 15px;
            }
          }
        }
      }
    }
  }

  // floating buttonsocialUrl
  .floatingButton {
    position: fixed;
    bottom: 60px;
    right: 15px;
    z-index: 999;
    padding: 8px 14px;
  }

  .sidebar {
    .logo {
      display: none;
    }

    .sidebar-wrapper {
      .mainNav {
        margin-top: 10px;
      }
    }
  }

  .main-panel {
    .content {
      margin-top: 0;
    }
  }

  .agentInnerWrap {
    margin: 0;
  }

  .responsiveTableCard {
    .dataWrap {
      .customSelectWrap {
        margin: 0 !important;

        .badge {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  .aboutCompany {
    position: unset !important;
    top: 0;
  }
}

@media screen and (max-width: 767.98px) {
  .innerTitleSection {
    border-bottom-left-radius: 60px;
    &::before {
      border-bottom-left-radius: 60px;
    }
  }

  .serviceGrid {
    .serviceCard {
      &.card {
        text-align: center;
        .card-body {
          h1 {
            font-size: 18px;
          }
        }
      }
    }
  }

  .personalWebsiteSetps {
    .tab-content {
      .tab-pane {
        padding: 0;
      }
    }
  }

  .contactSection {
    .contactLinks {
      text-align: center !important;
    }
  }

  .contactForm {
    margin-top: 50px;
    h2 {
      text-align: center;
    }
  }

  .whoWeSection {
    .row {
      flex-direction: column-reverse;
      text-align: center;
      border-bottom: 1px dashed $borderColor;
      padding-bottom: 20px;
      &:nth-child(even) {
        flex-direction: column-reverse;
      }
      .col-sm-12 {
        order: 1;
        &.text-right {
          text-align: center !important;
        }
      }
      .image-uploader {
        img {
          height: auto;
          margin-bottom: 40px !important;
        }
      }
    }
  }

  .serviceWrapper {
    .row {
      padding-bottom: 60px !important;
      flex-direction: column-reverse !important;
      border-bottom: 1px dashed $borderColor;
      margin-bottom: 25px;
      .col-lg-12 {
        order: 1;
        &.text-right {
          text-align: center !important;
        }
      }
      .serviceImage {
        margin: auto !important;
        margin-bottom: 40px !important;
        height: auto;
      }
      .serviceContent {
        text-align: center !important;
      }
    }
  }

  footer {
    .footerLink {
      margin-top: 30px;
      border-top: 1px dashed #474646;
      padding-top: 30px;
    }
  }
  .agentPageBuilder {
    footer {
      .copyright {
        text-align: center;
        span {
          display: block;
          width: 100%;
        }
        .otherLinks {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          span {
            width: auto;
          }
        }
      }
    }
  }

  .getStartWrapper {
    padding: 60px 0;
    h2 {
      font-size: 24px;
    }
    .getStartAction {
      .btn {
        display: block;
        width: 340px;
        padding: 15px 20px !important;
        margin: 15px auto !important;
      }
    }
  }

  .heroSection {
    border-bottom-left-radius: 100px;
    &::after {
      border-bottom-left-radius: 100px;
    }
    height: auto;
    padding: 100px 0;
    .heroContent {
      h1 {
        font-size: 30px;
      }
    }
  }

  .signingOfferButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .btn {
      display: block;
      width: 48%;
      margin: 0 !important;
      margin-bottom: 10px !important;
    }
  }

  .recordItems {
    ul {
      flex-wrap: wrap;
      li {
        width: 100%;
        padding-top: 15px;
        &:not(:last-child) {
          border-bottom: 1px dashed $borderColor;
          padding-bottom: 15px;
        }
      }
    }
  }
  .clientDetails {
    justify-content: space-between;

    li {
      flex-basis: 45%;
      margin-right: 0;
    }
  }

  .alreadyAssinged {
    ul {
      li {
        width: 100%;
      }
    }
  }

  .assignWrapper {
    .automationAction {
      button {
        font-size: 12px;
      }
    }
  }

  .agentCredentialsList {
    li {
      width: 100%;
      padding: 15px;
      border-bottom: 1px dashed $borderColor;

      &:last-child {
        border: none;
      }
    }
  }

  // quill

  .quill {
    .ql-toolbar {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;
      // overflow-x: auto;

      .ql-formats {
        margin-right: 5px;
        display: flex;
        flex: 1 1 auto;
        width: auto;
        flex-grow: 0;

        button {
          width: 20px;
          height: 20px;
        }

        .ql-header {
          font-size: 13px;
        }
      }
    }
  }

  // login
  .loginWrapper {
    flex-direction: column;

    .loginLeft {
      min-height: 200px;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      .projectLogo {
        margin: 0 0 20px;
      }

      .carouselContent,
      .loginDashboard {
        display: none;
      }
    }

    .loginRight {
      height: auto;
      flex: auto;
      align-items: flex-start;
      margin-top: -20px;
      border-top-left-radius: 20px;

      .orContinue {
        width: 300px;

        &::before,
        &::after {
          width: 80px;
        }
      }

      .FormWrap {
        h1 {
          margin-top: 20px;
        }

        h1,
        p {
          text-align: center;
        }
      }

      .bigLogo {
        width: 310px;
      }

      .registrationWrap {
        h2 {
          text-align: center;
        }
      }

      .registrationItem {
        justify-content: center;
      }
    }
  }

  // tabs
  .verticalTabs {
    flex-direction: column;

    > .nav-tabs {
      width: 100%;
      margin-bottom: 15px;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      white-space: nowrap;

      .nav-item {
        overflow: visible;

        .nav-link {
          margin: 0 !important;
          white-space: nowrap;
          padding-right: 30px;

          &::before {
            width: 100%;
            height: 3px;
            background-color: $primary;
          }
        }
      }
    }

    .tab-content {
      width: 100%;

      .tab-pane {
        padding-inline: 0;

        .profileInfo {
          flex-wrap: wrap;
          flex-direction: column;

          .uploadProfile {
            margin-inline: auto !important;
            margin-bottom: 20px !important;
          }

          button {
            width: 250px;
            margin: auto;
          }
        }

        .tab-content {
          .tab-pane {
            padding-inline: 15px;
          }
        }
      }
    }
  }
  .personalWebsiteSetps {
    .nav {
      // width: 100%;
      // flex-wrap: nowrap;
      // overflow: scroll;
      .nav-item {
        // flex: 1 0 auto;
        width: auto;
      }
    }
  }

  //filter
  .mobileFilterView {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s ease-in-out;
    background-color: rgba($black, 0.3);

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }

  .filterContainer {
    &.responsiveFilter {
      flex-direction: column;
      margin-bottom: 0px;
      background: #f4f7fe;
      z-index: 999;
      position: fixed;
      left: 0;
      width: 100%;
      bottom: 0;
      padding: 25px 0px;
      border-top-left-radius: 20px;
      transition: 0.4s ease-in-out;
      transform: translateY(100%);

      &.show {
        transform: translateY(0);
      }

      .mobileTitle {
        display: block;
        text-align: center;

        h2 {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 10px;
        }

        .closeButton {
          position: absolute;
          right: 15px;
          top: 20px;
        }
      }

      .clearButton {
        text-align: center;
        display: block;
        margin-top: 20px;
      }

      .filterWrapper {
        justify-content: space-between;
        padding: 10px 20px;
        max-height: 350px;
        overflow: auto;

        .form-group {
          width: 49% !important;
          max-width: inherit;
          margin-right: 0;

          &.searchTable {
            display: none;
          }

          .react-daterange-picker {
            width: 100% !important;
          }
        }
      }

      .downloadButton {
        display: none;
      }

      .filterIcon {
        display: none;
      }
    }
  }

  .responsiveTitle {
    .filterBarIcon {
      display: inline-block;
    }
  }

  .mobileSearchFiled {
    max-width: 400px;
    width: 100%;
    margin: 10px auto 20px;

    .input-group {
      height: 40px !important;
      border: none;
      box-shadow: 0 0 10px 0 #69b5ed29;

      .input-group-text {
        background-color: $white;
      }

      .form-control {
        font-weight: 400;
        color: #808080 !important;
      }
    }
  }

  .InputWithButton {
    flex-wrap: wrap;

    .form-group {
      width: 100%;
    }

    button {
      margin: auto;
      margin: 15px auto 25px;
    }
  }

  // subscription
  .subscriptionPlanCard {
    padding: 0 0 20px;

    .subscriptionTitle,
    .priceWrap {
      background-color: $primary;
      color: $white;
      margin: 0;
    }

    .subscriptionTitle {
      padding: 40px 20px 10px;
    }

    .priceWrap {
      padding-bottom: 15px;
      margin-bottom: 15px;

      span {
        &.subMonth {
          display: block;
        }
      }
    }

    .subscriptionContent {
      padding: 0 10px;
    }
  }

  .downloadButtonPos {
    position: fixed;
    bottom: 0;
    top: auto;
    width: 100%;
    left: 0;
    right: 0;
    background-color: rgb(241, 241, 241);
    z-index: 99;
    padding: 15px 10px;
    box-shadow: 0 -5px 0 0 rgba($black, 0.1);
    text-align: center;
  }

  .aboutCompany {
    &.signingClientDetails {
      .card-body {
        flex-wrap: wrap;
        flex-direction: column;

        .clientContent {
          text-align: center;
          margin-top: 30px;
          margin-left: 0;

          h5 {
            text-align: center;
          }
        }
      }
    }
  }
  .agentCoverBg {
    padding: 30px 30px 0;
  }
  .chatItem {
    .chatHeader {
      flex-direction: column-reverse;
      h2 {
        padding-bottom: 6px;
        padding-top: 10px;
        flex: auto;
        width: 100%;
      }
      .action {
        width: 100%;
      }
    }
  }

  // .instituteList {
  //   position: relative;
  //   top: 5px;
  // }
}

@media screen and (max-width: 575.98px) {
  .serviceGrid {
    .serviceCard {
      &.card {
        flex: 1 0 100%;
      }
    }
  }

  .contactSection {
    .contactForm {
      h2 {
        font-size: 24px;
      }
    }
  }
  .contactSection {
    .contactLinks {
      h2 {
        font-size: 24px;
      }
    }
  }

  .dropdown-container {
    width: 300px;
    z-index: 999999999;
  }

  .personalLogo {
    height: 40px;
  }

  .getStartWrapper {
    .getStartAction {
      .btn {
        width: 100%;
      }
    }
  }

  .verticalSteps {
    &::after {
      display: none;
    }
    li {
      z-index: 1;
      padding: 50px 10px !important;
      flex-direction: column-reverse;
      &:nth-child(2n) {
        flex-direction: column-reverse;
      }

      .stepContent,
      .stepIndicator {
        width: 100%;
        max-width: 100%;
        text-align: center;
      }

      &:after,
      &:before {
        display: none;
      }
    }
  }
  .heroSection {
    .heroContent {
      .btnAction {
        .btn {
          display: block;
          width: 90%;
          margin: 15px auto auto;
        }
      }
    }
  }

  .manageClientWrapper {
    .manageList {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .nav {
    &.nav-tabs {
      .nav-item {
        .nav-link {
          padding: 12px 10px;
        }
      }
    }
  }

  .filterContainer {
    &.responsiveFilter {
      .filterWrapper {
        .form-group {
          width: 100% !important;
        }
      }
    }
  }

  .calendarWrap {
    .calendarToolbar {
      .calendarTool {
        padding: 8px 0px 8px 8px !important;

        button {
          font-size: 11px;
          padding-left: 0 !important;

          img {
            height: 10px !important;
          }
        }
      }

      .chooseDate {
        button {
          font-size: 11px;
        }
      }

      .calendarFilter {
        .form-control {
          font-size: 11px;
        }

        .custom-select-wrapper {
          &::after {
            content: "";
            background: red;
            background-size: 1em;
          }
        }
      }
    }
  }

  .instructionCard {
    .card-header {
      flex-wrap: wrap;

      div {
        width: 100%;
        text-align: center;
      }

      button {
        text-align: center;
        width: 100%;
        padding-left: 0 !important;
        margin: 10px 0 5px;
      }
    }
  }

  .signingOfferButton {
    button {
      font-size: 14px;

      &:last-child {
        margin-top: 15px;
      }
    }
  }

  .clientDetails {
    li {
      flex-basis: 100%;
      margin-right: 0;
    }
  }

  .searchFiled {
    flex-wrap: wrap;
    .btn {
      margin-top: 15px;
    }
  }

  // sidebar
  .sidebar {
    .sidebar-wrapper {
      height: calc(100vh - 270px);
    }

    // .sidebarScheduleBox {
    //   .scheduleIcon {
    //     width: 40px;
    //     height: 40px;

    //     img {
    //       height: 20px;
    //       width: 20px;
    //     }
    //   }

    //   h2 {
    //     font-size: 12px;
    //   }

    //   button {
    //     height: auto;
    //     padding: 6px 15px;
    //     font-size: 12px;
    //   }
    // }
  }

  .agentPayPeriod {
    flex-direction: column;

    .buttonAction {
      margin-top: 15px;
    }
  }

  .jobsIncluded {
    flex-wrap: wrap;

    li {
      flex: 1;
      flex-grow: 1;
      flex-basis: 100px;
      text-align: center !important;
      margin: 5px 0;
    }
  }

  .appointmentInfo {
    flex-wrap: wrap;
    text-align: center;

    label {
      width: 100%;
    }
  }

  // subscription plan
  .selectedPlan {
    .card-body {
      flex-direction: column;
      text-align: center;

      h6,
      div {
        width: 100%;
      }
    }
  }

  .saveCardList {
    label {
      font-size: 13px;
    }
  }

  .modal {
    .modal-dialog {
      padding-top: 0 !important;
    }

    .modal-content {
      .modal-header {
        .modal-title {
          font-size: 16px;
        }
      }
    }

    .modal-footer {
      button {
        padding: 10px 20px;
        width: auto;
        font-size: 14px;
        white-space: nowrap;
      }
    }
  }

  .automationList {
    li {
      .automationItem {
        flex: 0 0 100%;
      }
    }
  }

  .barcodeWrap {
    .barcode {
      width: 250px;
      height: 250px;
    }

    button {
      max-width: 140px;
    }
  }

  .registrationItem {
    label {
      width: 110px;
      height: 110px;

      span {
        font-size: 12px;
      }

      img {
        height: 30px;
      }
    }
  }

  .verifiedCredentials {
    li {
      .accordianHeader {
        > div {
          font-size: 12px;
        }
      }
    }
  }
  // .headerWithButton {
  //   margin-bottom: 1rem;

  //   height: 50%;
  // }

  // .deleteButton {
  //   align-self: flex-end; /* Adjust spacing as needed */
  //   margin-top: 8px;
  // }

  .standardFeeWrap {
    .form-group {
      width: 100%;
      padding-inline: 0 !important;
    }
  }

  .paginationWrap {
    flex-wrap: wrap;
    justify-content: center;

    .showng_entries {
      margin-bottom: 20px;
      width: 100%;
      display: block;
      text-align: center;
    }
  }

  .filterContainer {
    &.responsiveView {
      .filterWrapper {
        .form-group {
          width: 100% !important;
          max-width: unset !important;
          margin-right: 0;

          &.dateRange {
            .react-daterange-picker {
              width: 100% !important;
            }
          }
        }
      }
    }
  }

  .responsiveTableCard {
    .cardInfo {
      li {
        flex-wrap: wrap;
        border-bottom: none !important;
        padding: 0 !important;

        .dataWrap {
          padding: 15px !important;
          flex: auto;
          width: 100%;
          border-bottom: 1px dashed $borderColor;
        }

        &:last-child {
          .dataWrap {
            &:last-child {
              border: none;
            }
          }
        }
      }
    }
  }

  // tabs
  .nav-tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 1px;

    .nav-item {
      white-space: nowrap;
    }
  }

  .tab-content {
    .tab-pane {
      padding: 10px;
    }
  }

  .documentList,
  .expensesList {
    li {
      padding: 8px;

      .documentItem,
      .ExpenseItem {
        max-width: unset !important;
        font-size: 11px;
      }

      .actionItem {
        width: 80px;

        button {
          padding: 0 !important;

          &:nth-last-child(1) {
            border-left: none !important;
            margin-left: 15px !important;
          }
        }
      }
    }
  }

  .documentItem {
    .userInfo {
      width: 130px;
    }
  }

  // info card
  .infoCard {
    .card-body {
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column-reverse;

      > div {
        width: 100%;
        text-align: center;
      }

      .icon {
        margin-bottom: 15px;
      }
    }
  }

  .responsiveTitle {
    h2 {
      font-size: 14px;
    }
  }

  .aboutWrapper {
    max-width: 100%;

    .agentInnerWrap {
      margin-inline: 10px;
    }
  }

  .bookAppointment {
    .card-header {
      .card-title {
        font-size: 12px;
      }
    }
  }

  .CompanyStats {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;

    .icon {
      margin-bottom: 15px;
    }
  }

  .standardFee {
    li {
      flex: 0 0 48%;
    }
  }

  .swal2-popup {
    .swal2-actions {
      .swal2-styled {
        padding: 10px 20px;
        font-size: 14px;
      }
    }
  }
  .questionPoint {
    li {
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
  .barContainer {
    display: none;
  }
}
@media screen and (max-width: 442px) {
  .questionPoint {
    li {
      margin-right: 0;
    }
  }
  .signingOfferButton {
    .btn {
      display: block;
      width: 48%;
      margin: 0 !important;
      margin-bottom: 10px !important;
      &:nth-child(3n) {
        width: 100%;
      }
      &:nth-child(4n) {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .assignWrapper {
    .assignWrap {
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;

      .custom-radio,
      h4 {
        width: 100%;
      }
    }
  }

  .creditCardWrapper {
    .icon {
      svg {
        height: 22px;
      }
    }
    .creditNumber {
      h3 {
        font-size: 14px !important;
        span {
          svg {
            width: 14px !important;
            height: 14px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 340px) {
  .signingOfferButton {
    button {
      font-size: 14px;
      margin-top: 0 !important;
      margin-bottom: 15px !important;
    }
  }

  .modal {
    .modal-footer {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      padding: 0 0 10px !important;

      button {
        margin: 10px !important;
        display: inline-block !important;
        width: calc(100% - 20px);
      }
    }
  }
}

// media query according height
@media screen and (max-height: 740px) and (min-width: 800px) and (max-width: 1199px) {
  .loginWrapper {
    .loginLeft {
      .loginDashboard {
        height: 50vh;
        width: 95%;
      }
    }
  }
}

@media screen and (max-height: 630px) and (min-width: 800px) and (max-width: 1366px) {
  .loginWrapper {
    .loginLeft {
      .projectLogo {
        margin-bottom: 10px;
      }

      .carouselContent {
        .carousel-caption {
          h3 {
            font-size: 20px;
            margin-bottom: 10px;
          }
        }
      }

      .loginDashboard {
        height: 48vh;
        width: 90%;
      }
    }

    .loginRight {
      .bigLogo {
        width: 300px;
      }

      &.signUpPage {
        align-items: flex-start;
      }
    }
  }
}
